// src/components/home/SocialProofSection.tsx

import React from "react";
import { StatsSection } from "./StatsSection";

const testimonials = [
    {
        name: "Alice Johnson",
        title: "Professional Trader",
        feedback: "BoneX has transformed my trading journey! The funding options are unbeatable, and I love the profit share.",
        image: "./images/social-proof/1.jpg" // Placeholder image
    },
    {
        name: "Bob Smith",
        title: "Forex Trader",
        feedback: "I've never seen such great support and funding options. Highly recommend BoneX to all traders!",
        image: "./images/social-proof/2.jpg"
    },
    {
        name: "Charlie Brown",
        title: "Crypto Enthusiast",
        feedback: "BoneX offers the best funding I’ve come across! The process was straightforward and easy to understand.",
        image: "./images/social-proof/3.jpg"
    }
];

export const SocialProofSection: React.FC = () => {
    return (
        <section className="social-proof-section" style={{ padding: "50px 0", backgroundColor: "black" }}>
            <div className="container" id='social-proof'>
                <StatsSection />
                {/* <h2 className="text-center">What Our Clients Say</h2> */}
                {/* <div className="row">
                    {testimonials.map((testimonial, index) => (
                        <div className="col-lg-4 col-md-6" key={index} style={{ marginBottom: "30px" }}>
                            <div className="testimonial-card" style={{ border: "1px solid #c1a96c", borderRadius: "8px", padding: "20px", textAlign: "center" }}>
                                <img src={testimonial.image} alt={testimonial.name} style={{ borderRadius: "50%", width: "200px", height: "200px" }} />
                                <h3 style={{ margin: "15px 0" }}>{testimonial.name}</h3>
                                <h5 style={{ color: "#c1a968" }}>{testimonial.title}</h5>
                                <p style={{ fontStyle: "italic" }}>"{testimonial.feedback}"</p>
                            </div>
                        </div>
                    ))}
                </div> */}
            </div>
        </section>
    );
};
