import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

// Pages
import { AboutUsPage } from "./pages/AboutUsPage";
import { HomePage } from "./pages/HomePage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { RefundPolicyPage } from "./pages/RefundPolicyPage";
import { TermsOfConditionsPage } from "./pages/TermsOfConditionsPage";
import { AffiliatesPage } from "./pages/AffiliatesPage";
import { GetFundedPage } from "./pages/GetFundedPage";

export class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/affiliates" element={<AffiliatesPage />} />
          <Route path="/get-funded" element={<GetFundedPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-conditions" element={<TermsOfConditionsPage />} />
          <Route path="/refund-policy" element={<RefundPolicyPage />} />
        </Routes>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
