import React from "react";
import { Layout } from "../layout";
import StarField from "../components/home/StarField";
import GetFunded from "../components/home/GetFunded";
import { FundTradingProgramDto } from "../lib/drivers/dto/FundTradingProgramDto";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { Authenticate } from "../components/home/Authenticate";
import { parseCookies, setCookie } from "nookies";
import ConfirmFund from "../components/home/ConfirmFund";


export type GetFundedPageProps = {};

type GetFundedPageState = {
    step: number;
    dataFundTradingProgramListList: FundTradingProgramDto[];
    fundTradingProgramId: string;
    fundTradingProgram: FundTradingProgramDto;
    invitationCode: string;
    addons: string[];
    slider: number;
    phase: string;
};

export class GetFundedPage extends React.Component<GetFundedPageProps, GetFundedPageState> {
    constructor(props: GetFundedPageProps) {
        super(props);
        this.state = {
            step: 1,
            dataFundTradingProgramListList: [],
            fundTradingProgramId: "",
            fundTradingProgram: null,
            invitationCode: "",
            addons: [],
            slider: 0,
            phase: ""
        };
    }

    // #region React Events
    componentDidMount = async () => {
        const params = new URLSearchParams(window.location.search);
        const cookies = parseCookies();

        const slider = params.get("slider");
        const phase = params.get("phase");

        const fundTradingProgramId = params.get("fundTradingProgramId");
        const addons = params.getAll("addon");
        const invitationCode = params.get("invitation");
        const authToken = cookies["meta.token"];

        if (invitationCode) {
            setCookie(null, "invitationCode", invitationCode, { maxAge: 3600, path: "/" });

            this.setState({
                invitationCode: invitationCode
            })
        }

        if (slider && phase) {
            console.log({ slider, phase })
            this.setState({
                slider: Number(slider),
                phase: phase
            })
        }

        if (addons) {
            this.setState({
                addons: addons,
            })
        }

        if (fundTradingProgramId) {
            this.setState({
                fundTradingProgramId: fundTradingProgramId,
                step: 2
            })
        }

        if (fundTradingProgramId && authToken) {
            this.setState({
                fundTradingProgramId: fundTradingProgramId,
                step: 3
            })
        }

        const dataFundTradingProgramListList = await this.loadFundTradingProgramList();

        await this.setState((prevState) => {
            return {
                ...prevState,
                dataFundTradingProgramListList: dataFundTradingProgramListList
            };
        });
    };

    componentDidUpdate = async (prevProps: GetFundedPageProps, prevState: GetFundedPageState) => {
        // execute
    };

    componentWillUnmount = async () => {
        // destroy connection
    };
    // #endregion

    // #region Handlers
    async loadFundTradingProgramList(): Promise<FundTradingProgramDto[]> {
        const result = await PortCentralServer.Repo.client.getFundTradingProgramList();

        return result;
    }
    // #endregion

    StepContent = () => {
        return (
            <div style={{ paddingTop: "7rem", paddingBottom: "2rem" }}>
                {this.state.step === 1 ? <GetFunded dataFundTradingProgramList={this.state.dataFundTradingProgramListList} isGetFundedPage={true} slider={this.state.slider} phase={this.state.phase} /> : <></>}
                {this.state.step === 2 ? <Authenticate invitationCode={this.state.invitationCode} /> : <></>}
                {this.state.step === 3 ? <ConfirmFund fundTradingProgramId={this.state.fundTradingProgramId} addons={this.state.addons} /> : <></>}
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Layout>
                    <main className="nk-pages">
                        <section className="position-relative">
                            <div className="header-banner  has-ovm has-mask position-relative">
                                <div className="position-absolute" style={{ height: "100%", width: "100%" }}>
                                    <div className="home-hero"></div>
                                    <StarField />
                                </div>
                                <div>
                                    <this.StepContent />
                                </div>
                            </div>
                        </section>
                    </main>
                </Layout>
            </React.Fragment>
        );
    }
}
