import { Transak, TransakConfig } from "@transak/transak-sdk";
import React from "react";
import { AppContext } from "../../context/AppProvider";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { IProgramAddonItem } from "../../lib/drivers/dto/config/IProgramAddonItem";
import { ProgramAddonType } from "../../lib/drivers/dto/enums/ProgramAddonType";
import { FundTradingProgramDto } from "../../lib/drivers/dto/FundTradingProgramDto";
import { PurchaseProviderType } from "../../lib/drivers/dto/enums/PurchaseProviderType";
import ReactPixel from 'react-facebook-pixel';
import { PromoCodeDto } from "../../lib/drivers/dto/PromoCodeDto";
import { Input } from "reactstrap";

const DETAILS: { [key: number]: { accountSizeNum: string; title: string; subtitle: string } } = {
    5_000: { accountSizeNum: "5000", title: "Kickstarter Tier", subtitle: "Start Small, Dream Big: Begin Your Trading Journey with Essential Funding." },
    10_000: { accountSizeNum: "10000", title: "Starter Plus Tier", subtitle: "Step Up Your Game: More Funding for Confident Beginnings." },
    25_000: { accountSizeNum: "25000", title: "Accelerator Tier", subtitle: "Boost Your Potential: Enhanced Funding for Ambitious Traders." },
    50_000: { accountSizeNum: "50000", title: "Advanced Tier", subtitle: "Take the Lead: Substantial Funding for Growing Traders." },
    100_000: { accountSizeNum: "100000", title: "Pro Tier", subtitle: "Maximize Your Impact: Premium Funding for Serious Traders." },
    200_000: { accountSizeNum: "200000", title: "Elite Tier", subtitle: "Unlock the Ultimate: Top-Tier Funding for Expert-Level Trading." },
    500_000: { accountSizeNum: "200000", title: "Elite Tier", subtitle: "Unlock the Ultimate: Top-Tier Funding for Expert-Level Trading." },
    1_000_000: { accountSizeNum: "200000", title: "Elite Tier", subtitle: "Unlock the Ultimate: Top-Tier Funding for Expert-Level Trading." }
};

type AddonSelectionItem = {
    addon: IProgramAddonItem;
    selected: boolean;
    title: string;
};

export type ConfirmFundProps = {
    fundTradingProgramId: string;
    addons: string[];
};

export type PaymentOptions = {
    usdt: boolean;
    card: boolean;
};

type MoonpayData = {
    visible: boolean;
    baseCurrencyCode: string;
    baseCurrencyAmount: number;
    defaultCurrencyCode: string;
    walletAddress: string;
    showWalletAddressForm: boolean;
    lockAmount: boolean;
    email: string;
    externalTransactionId: string;
    externalCustomerId: string;
};

type ConfirmFundState = {
    program: FundTradingProgramDto;
    initialPrice: number;
    initialDiscountPrice?: number | null;
    programId: string;
    userSubscriptionPurchaseId: string;
    userEmail: string;
    paymentOption: PaymentOptions;
    moonpayData: MoonpayData;
    addons: AddonSelectionItem[];
    promoCode: string;
};

class ConfirmFund extends React.Component<ConfirmFundProps, ConfirmFundState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: ConfirmFundState = {
        program: null,
        initialPrice: 0,
        initialDiscountPrice: null,
        programId: "",
        userSubscriptionPurchaseId: null,
        userEmail: null,
        paymentOption: {
            usdt: true,
            card: false
        },
        moonpayData: {
            visible: false,
            baseCurrencyCode: "usd",
            baseCurrencyAmount: 0,
            // TODO => Change currency and wallet
            defaultCurrencyCode: "eth",
            walletAddress: process.env.REACT_APP_MOONPAY_RECIEVE_WALLET,
            showWalletAddressForm: false,
            lockAmount: true,
            email: "",
            externalTransactionId: "",
            externalCustomerId: ""
        },
        addons: null,
        promoCode: ""
    };

    // #region React Events
    componentDidMount = async () => {
        // setup connection
        const fundTradingProgramId = this.props.fundTradingProgramId;
        const currProgram: FundTradingProgramDto = await PortCentralServer.Repo.client.getFundTradingProgram(fundTradingProgramId);

        const addons: IProgramAddonItem[] = await PortCentralServer.Repo.client.getFundTradingProgramAddonList();

        const addonSelectionList: AddonSelectionItem[] = addons.map((addon) => ({
            addon,
            selected: this.props.addons.includes(addon.type),
            title: this.getAddonTitle(addon.type)
        }));

        await this.setState({
            ...this.state,
            program: currProgram,
            initialPrice: currProgram.price,
            initialDiscountPrice: currProgram.discountPrice,
            programId: fundTradingProgramId,
            addons: addonSelectionList
        }, () => this.calculatePrice()
        );
    };

    componentDidUpdate = async (prevProps: ConfirmFundProps, prevState: ConfirmFundState) => {
        // execute
    };

    componentWillUnmount = async () => {
        // destroy connection
    };
    // #endregion

    // #region Handlers
    submitPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): void {
        // Create a blurred background overlay
        const overlay = document.createElement("div");
        overlay.style.position = "fixed";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.width = "100vw";
        overlay.style.height = "100vh";
        overlay.style.backgroundColor = "transparent"; // Semi-transparent black background
        overlay.style.backdropFilter = "blur(5px)"; // Apply blur effect to the background
        overlay.style.zIndex = "999"; // Ensure the overlay is on top
        document.body.appendChild(overlay);

        // Create the iframe
        const iframe = document.createElement("iframe");
        iframe.name = "paymentFrame";
        iframe.style.position = "fixed";
        iframe.style.top = "50%";
        iframe.style.left = "50%";
        iframe.style.transform = "translate(-50%, -50%)"; // Center the iframe

        // Set responsive width/height for mobile
        iframe.style.width = "80vw"; // 90% of viewport width
        iframe.style.height = "80vh"; // 80% of viewport height
        iframe.style.border = "1px solid black";
        iframe.style.borderRadius = "10px"; // Rounded corners for better UX
        iframe.style.overflow = "auto"; // Add scroll if content overflows
        iframe.style.zIndex = "1000"; // Ensure the iframe is above the overlay
        document.body.appendChild(iframe);

        // Create the close button
        const closeButton = document.createElement("button");
        closeButton.innerText = "X";
        closeButton.style.position = "fixed";
        closeButton.style.top = "calc(50% - 37vh)"; // Adjust relative to iframe height
        closeButton.style.left = "calc(50% + 40vw - 25px)"; // Adjust relative to iframe width
        closeButton.style.transform = "translate(-50%, -50%)";
        closeButton.style.backgroundColor = "#000";
        closeButton.style.color = "#fff";
        closeButton.style.border = "none";
        closeButton.style.padding = "10px";
        closeButton.style.fontSize = "18px";
        closeButton.style.cursor = "pointer";
        closeButton.style.zIndex = "1001"; // Ensure the button is on top
        document.body.appendChild(closeButton);

        // Handle closing the iframe and overlay
        closeButton.onclick = () => {
            document.body.removeChild(iframe);
            document.body.removeChild(overlay);
            document.body.removeChild(closeButton);
            window.location.replace('https://app.bonex.fund/accounts');
        };

        // Create the form and set its target to the iframe
        const form = this.createPaymentForm(vendor, amount, symbol, inv, email);
        form.target = "paymentFrame";
        document.body.appendChild(form);

        // Submit the form
        form.submit();
    }

    createPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): HTMLFormElement {
        const form = document.createElement("form") as HTMLFormElement;
        form.method = "POST";
        form.action = "https://payment.bonex.fund/order_popup";
        form.target = "paymentFrame"; // Set the target to the iframe

        form.appendChild(this.createHiddenInput("vendor", vendor));
        form.appendChild(this.createHiddenInput("amount", amount.toString()));
        form.appendChild(this.createHiddenInput("symbol", symbol));
        form.appendChild(this.createHiddenInput("inv", inv));
        form.appendChild(this.createHiddenInput("email", email));

        return form;
    }

    createHiddenInput(name: string, value: string): HTMLInputElement {
        const input = document.createElement("input") as HTMLInputElement;
        input.type = "hidden";
        input.name = name;
        input.value = value;
        return input;
    }

    async fetchCurrentNetworkFee(): Promise<number> {
        const response = await fetch("https://api.etherscan.io/api?module=proxy&action=eth_gasPrice&apikey=D2BKV896XWKP8EGM7N1TGQT77TR4V2VJ38");
        const data = await response.json();
        const gasPriceGwei = parseInt(data.result, 16) / 1e9; // Convert from wei to Gwei

        // Assuming an average gas limit of 21000 for standard ETH transactions, adjust as necessary for USDT
        const gasLimit = 21000; // You can adjust this for USDT transactions
        const networkFeeInEth = (gasPriceGwei * gasLimit) / 1e9; // Convert Gwei to Ether

        // Fetch current ETH price in USD to convert network fee to USD
        const ethPriceResponse = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd");
        const ethPriceData = await ethPriceResponse.json();
        const ethPriceInUSD = ethPriceData.ethereum.usd;

        // Convert network fee from ETH to USD
        const networkFeeInUSD = networkFeeInEth * ethPriceInUSD;

        return networkFeeInUSD;
    }

    async calculateTotalTransakPrice(amount: number, countryCode: string): Promise<number> {
        const processingFeePercentage = 5;
        const constantFee = 2;

        // Fetch the current network fee
        const networkFee = await this.fetchCurrentNetworkFee();

        const fee = Math.max(amount * (processingFeePercentage / 100));

        // Return the total cost (amount + fee + current network fee)
        return amount + fee + constantFee + networkFee * 5;
    }

    async applyPromoCode() {
        const promoCodeData: PromoCodeDto = await PortCentralServer.Repo.client.getPromoCode(this.state.promoCode);

        if (promoCodeData && promoCodeData.active) {
            const { discountPercentage } = promoCodeData;
            const { price } = this.state.program;

            const promoCodeDiscount = price * (1 - (Number(discountPercentage) / 100));

            this.setState({
                program: {
                    ...this.state.program,
                    discountPercentage: discountPercentage,
                    discountPrice: promoCodeDiscount
                }
            })
        }
    }

    handleInputChange = (e: any) => {
        this.setState({ promoCode: e.target.value });
    };

    processBuy = async () => {
        const addons = this.getSelectedAddons();

        ReactPixel.init('1018838623261768');
        ReactPixel.track('Purchase', {
            content_name: 'Funded Account',
            content_category: 'Funded Accounts',
            content_ids: ['1234'],
            content_type: 'product',
            value: 49,
            currency: 'USD',
        });

        if (this.state.paymentOption.usdt) {
            if (!this.state.userSubscriptionPurchaseId) {
                const { userSubscriptionPurchaseId, userEmail } = await PortCentralServer.Repo.client.buySubscription({
                    fundTradingProgramId: this.state.programId,
                    provider: PurchaseProviderType.BXCHECKOUT,
                    addons: addons,
                    promoCode: this.state.promoCode
                });

                this.setState({
                    ...this.state,
                    userSubscriptionPurchaseId: userSubscriptionPurchaseId,
                    userEmail: userEmail
                });

                this.createInvoice(userSubscriptionPurchaseId, userEmail);
            } else {
                this.createInvoice(this.state.userSubscriptionPurchaseId, this.state.userEmail);
            }
        } else if (this.state.paymentOption.card) {
            // const { userSubscriptionPurchaseId, userEmail, userId } = await PortCentralServer.Repo.client.buySubscription({
            //   fundTradingProgramId: this.state.programId,
            //   provider: PurchaseProviderType.MOONPAY,
            //   network: "ethereum",
            //   symbol: this.state.moonpayData.defaultCurrencyCode.toUpperCase(),
            //   address: this.state.moonpayData.walletAddress,
            // });

            // this.setState({
            //   ...this.state,
            //   moonpayData: {
            //     ...this.state.moonpayData,
            //     visible: true,
            //     baseCurrencyAmount: this.calculateTotalMoonpayPrice(Number(this.state.program?.discountPrice || this.state.program.price)),
            //     email: userEmail,
            //     externalTransactionId: userSubscriptionPurchaseId,
            //     externalCustomerId: userId
            //   }
            // })

            const { userSubscriptionPurchaseId, userEmail, userId } = await PortCentralServer.Repo.client.buySubscription({
                fundTradingProgramId: this.state.programId,
                provider: PurchaseProviderType.TRANSAK,
                network: "ethereum",
                symbol: this.state.moonpayData.defaultCurrencyCode.toUpperCase(),
                address: this.state.moonpayData.walletAddress,
                addons: addons,
                promoCode: this.state.promoCode
            });

            const request = await fetch("https://ipinfo.io/json?token=18000387090f02");
            const jsonResponse = await request.json();
            const countryCode = jsonResponse.country;

            const fiatAmount = await this.calculateTotalTransakPrice(Number(this.state.program?.discountPrice || this.state.program.price), countryCode);

            const transakConfig: TransakConfig = {
                apiKey: process.env.REACT_APP_TRANSAK_API_KEY,
                environment: Transak.ENVIRONMENTS.PRODUCTION,
                productsAvailed: "BUY",
                network: "ethereum",
                countryCode: countryCode,
                fiatCurrency: "USD",
                fiatAmount,
                cryptoCurrencyCode: "USDT",
                walletAddress: process.env.REACT_APP_TRANSAK_RECIEVE_WALLET,
                email: userEmail,
                partnerOrderId: userSubscriptionPurchaseId,
                partnerCustomerId: userId
            };

            const transak = new Transak(transakConfig);

            transak.init();

            Transak.on("*", (data: any) => {
                // console.log(data);
            });

            // This will trigger when the user closed the widget
            Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
                transak.close();
            });

            /*
             * This will trigger when the user has confirmed the order
             * This doesn't guarantee that payment has completed in all scenarios
             * If you want to close/navigate away, use the TRANSAK_ORDER_SUCCESSFUL event
             */
            Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, () => {
                // console.log(orderData);
            });

            /*
             * This will trigger when the user marks payment is made
             * You can close/navigate away at this event
             */
            Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, () => {
                // console.log(orderData);
                transak.close();
                window.location.replace('https://app.bonex.fund/accounts');
            });
        }
    };

    createInvoice = async (userSubscriptionPurchaseId: string, userEmail: string) => {
        this.submitPaymentForm("123456789abcdef", this.state.program?.discountPrice || this.state.program.price, "USDT", userSubscriptionPurchaseId, userEmail);
    };
    // #endregion

    // #region Functions
    getAddonTitle(type: ProgramAddonType): string {
        switch (type) {
            case ProgramAddonType.BI_WEEKLY_PAYOUT:
                return "Bi-Weekly Payout";
            case ProgramAddonType.WEEKLY_PAYOUT:
                return "Weekly Payout";
            case ProgramAddonType.NO_MIN_TRADING_DAYS:
                return "No Minimum Trading Days";
            default:
                return "Unknown Addon";
        }
    }

    async calculatePrice(): Promise<void> {
        const initialPrice: number = Number(this.state.initialPrice);
        const initialDiscountPrice: number = this.state.initialDiscountPrice;

        let actualPrice: number = Number(this.state.initialPrice);
        let actualDiscountPrice: number = Number(this.state.initialDiscountPrice);

        for (const selectedAddon of this.state.addons) {
            if (selectedAddon.selected === true) {
                const addonCost = initialPrice * (selectedAddon.addon.priceChangePercentage / 100);
                actualPrice = actualPrice + addonCost;

                if (this.state.initialDiscountPrice && this.state.initialDiscountPrice > 0) {
                    const addonCostDiscount = initialDiscountPrice * (selectedAddon.addon.priceChangePercentage / 100);
                    actualDiscountPrice = actualDiscountPrice + addonCostDiscount;
                }
            }
        }

        // round
        const roundedPrice = Math.round(actualPrice * 100) / 100;
        const roundedDiscountPrice = Math.round(actualDiscountPrice * 100) / 100;

        await this.setState((prevState) => ({
            program: {
                ...prevState.program,
                price: roundedPrice,
                discountPrice: roundedDiscountPrice
            }
        }));
    }
    // #endregion

    getSelectedAddons(): IProgramAddonItem[] {
        const result = this.state.addons.filter((addonItem) => addonItem.selected).map((addonItem) => addonItem.addon);

        return result;
    }

    isAddonSelected(type: ProgramAddonType): boolean {
        const targetAddon = this.state.addons.find((ele) => ele.selected && ele.addon.type == type);

        return targetAddon != null;
    }

    render() {
        let currentTitle = { accountSizeNum: "", title: "", subtitle: "" };
        if (this.state.program !== null) {
            currentTitle = DETAILS[Number(this.state.program.accountSize)];
        }

        let selectedAddons = this.state.addons?.filter(addon => addon.selected);
        // const programTitle = currentTitle[0].title

        return (
            <div className="page-content" style={{ marginTop: "-2.1rem", backgroundColor: "rgb(0 0 0 / 87%)" }}>
                <div className="row">
                    <div className="col-12 justify-content-center">
                        <img src="../../images/bull-logo.png" style={{ width: "15rem", display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "2rem   " }} />
                    </div>

                    <div className="col-12 col-md-7 ">
                        <div>
                            <div className="text-center mb-5">
                                <h3 style={{ color: "white", fontSize: "2rem" }}>
                                    Challenge Account Confirmation
                                </h3>
                                <h4 style={{ color: "white", fontSize: "1.9rem", fontWeight: "300" }}>
                                    {currentTitle && currentTitle.title} Funding ${currentTitle && Number(currentTitle.accountSizeNum).toLocaleString()}{" "}
                                </h4>
                                <p className="mb-4">{currentTitle && currentTitle.subtitle}</p>{" "}

                                <h5 className="mb-4" style={{ color: "white" }}>
                                    <div className="row">
                                        {this.state.program?.fundTradingProgramStages.length === 2 ? <div className={`plan-features mt-5 col-md-3`}></div> : <></>}
                                        {this.state.program?.fundTradingProgramStages.length === 3 ? <><div className={`plan-features mt-5 col-md-2`}></div><div className={`plan-features mt-5 col-md-1`}></div></> : <></>}
                                        {this.state.program !== null &&
                                            this.state.program.fundTradingProgramStages.map((stage, index) => (
                                                <div key={index} className={`plan-features mt-5 ${this.state.program.fundTradingProgramStages.length === 2 ? "col-md-3" : "col-md-2"}`}>
                                                    <h3>
                                                        {stage.type} {stage.type === "PHASE" ? stage.sequence + 1 : ""}
                                                    </h3>
                                                    <p>
                                                        <i className="fa fa-caret-right font-size-16 align-middle me-2" />
                                                        <span className="token-stage-bonus">{stage.maxDailyLoss}%</span> <span className="token-stage-cap">Max daily loss</span>
                                                    </p>
                                                    {stage.maxTotalLoss && (
                                                        <p>
                                                            <i className="fa fa-caret-right font-size-16 align-middle me-2" />
                                                            <span className="token-stage-bonus">{stage.maxTotalLoss}%</span> <span className="token-stage-cap">Max loss</span>
                                                        </p>
                                                    )}
                                                    {stage.maxTrailingLoss && (
                                                        <p>
                                                            <i className="fa fa-caret-right font-size-16 align-middle me-2" />
                                                            <span className="token-stage-bonus">{stage.maxTrailingLoss}%</span> <span className="token-stage-cap">Max trailing loss</span>
                                                        </p>
                                                    )}
                                                    {stage.profitTarget !== null && (
                                                        <p>
                                                            <i className="fa fa-caret-right font-size-16 align-middle me-2" />
                                                            <span className="token-stage-bonus">{stage.profitTarget} %</span> <span className="token-stage-cap">Phase {[index + 1]} target</span>
                                                        </p>
                                                    )}

                                                    {stage.minTradingDays !== null && (
                                                        <>
                                                            {this.isAddonSelected(ProgramAddonType.NO_MIN_TRADING_DAYS) ? (
                                                                <>
                                                                    <p>
                                                                        <del>
                                                                            <i className="fa fa-caret-right font-size-16 align-middle me-2" />
                                                                            <span className="token-stage-bonus">{stage.minTradingDays}</span> <span className="token-stage-cap">Min trading days</span>
                                                                        </del>
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p>
                                                                        <i className="fa fa-caret-right font-size-16 align-middle me-2" />
                                                                        <span className="token-stage-bonus">{stage.minTradingDays}</span> <span className="token-stage-cap">Min trading days</span>
                                                                    </p>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                    </div>

                                    {selectedAddons?.length > 0 && (
                                        <div className="row mt-4">
                                            <hr />

                                            <div className="col">
                                                <div className="product-color">
                                                    <h5 className="font-size-15" style={{ color: "white" }}>Addons :</h5>
                                                    {selectedAddons.map((item: AddonSelectionItem, key: number) => (
                                                        item.selected ?
                                                            <a
                                                                key={key}
                                                                className={`addon-item ${selectedAddons.length === 1 ? "" : "me-3"} ${item.selected ? "active" : ""}`}
                                                            >
                                                                <div className="addon-content">
                                                                    <span className="addon-title">{item.title}</span>
                                                                    <span className="addon-percentage">+ {item.addon.priceChangePercentage} %</span>
                                                                </div>
                                                            </a> : <></>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 justify-content-start" style={{ backgroundColor: "#ffffff21", borderRadius: "4rem" }}>
                        <div className="card-body">
                            <div className="wizard clearfix" style={{ textAlign: "left", marginLeft: "3rem" }}>
                                <div className="content clearfix">
                                    <div className={`tab-content body active`}>
                                        <div className={`tab-pane active`}>
                                            {
                                                // currentTitle[0].
                                                <div>
                                                    <h5>
                                                        <br />
                                                        {this.state.program?.discountPrice ? (
                                                            <span style={{ color: "white", marginBottom: "2rem", marginTop: "2rem" }}>
                                                                <del>
                                                                    Challenge Account Price : {" "}
                                                                    <span className="me-2">
                                                                        <b style={{ color: "white" }}>{this.state.program && Number(this.state.program.price).toLocaleString()} USDT</b>
                                                                    </span>{" "}
                                                                </del>
                                                                <br />
                                                                <>
                                                                    <br />

                                                                    You Saved : {" "}
                                                                    <span className="me-2">
                                                                        <b style={{ color: "white" }}>{this.state.program && Math.abs(Number(this.state.program.discountPrice) - Number(this.state.program.price)).toLocaleString()} USDT</b>
                                                                    </span>{" "}
                                                                    <br />
                                                                    <br />

                                                                    Discount Price : {" "}
                                                                    <span className="me-2">
                                                                        <b style={{ color: "white" }}>{this.state.program && this.state.program.discountPrice.toLocaleString()} USDT</b>
                                                                    </span>{" "}
                                                                </>
                                                            </span>
                                                        ) : (
                                                            <span style={{ color: "white", marginBottom: "2rem", marginTop: "2rem" }}>
                                                                Challenge Account Price : {" "}
                                                                <span className="me-2">
                                                                    <b>{this.state.program && Number(this.state.program.price).toLocaleString()} USDT</b>
                                                                </span>{" "}
                                                            </span>
                                                        )}
                                                        <hr />
                                                        <br />

                                                        <>
                                                            <h5 className="font-size-15 mb-3" style={{ color: "white" }}>Promo code:</h5>
                                                            <input
                                                                name="promoCode"
                                                                className="form-control"
                                                                type="text"
                                                                style={{ width: '15rem', color: "white" }}
                                                                onChange={this.handleInputChange}
                                                            />
                                                            <br />
                                                            <button className="btn btn-beige btn-round" type="submit" onClick={() => this.applyPromoCode()}>
                                                                Apply
                                                            </button>
                                                            <hr />
                                                            <br />
                                                            <span style={{ color: "white" }}>
                                                                Payment Provider :
                                                            </span>
                                                            <br />
                                                            <div className="phase-toggle-btn-group" style={{ marginTop: "1rem", maxWidth: "16rem", position: "relative" }}>
                                                                <button
                                                                    className={`phase-toggle-btn ${this.state.paymentOption.usdt ? "active" : ""}`}
                                                                    onClick={() => this.setState({
                                                                        ...this.state,
                                                                        paymentOption: {
                                                                            usdt: true,
                                                                            card: false
                                                                        }
                                                                    })}
                                                                >
                                                                    USDT
                                                                </button>
                                                                <button
                                                                    className={`phase-toggle-btn ${this.state.paymentOption.card ? "active" : ""}`}
                                                                    onClick={() => this.setState({
                                                                        ...this.state,
                                                                        paymentOption: {
                                                                            usdt: false,
                                                                            card: true
                                                                        }
                                                                    })}
                                                                >
                                                                    Credit card
                                                                </button>
                                                            </div>
                                                            <hr />
                                                        </>
                                                    </h5>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <input name="terms" type="checkbox"></input>{" "}
                                <label style={{ color: "white" }}> I agree with the <a href="/terms-of-conditions">Terms and Conditions</a></label>
                                <br />
                                <button type="submit" id="purchase" className="btn btn-beige btn-round" style={{ marginTop: "2rem", marginBottom: "2rem" }} onClick={this.processBuy}>
                                    Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <style>
                    {`
                    .addons-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    }

                    .addon-item {
                    display: inline-block;
                    padding: 10px 15px;
                    border: 1px solid white;
                    border-radius: 1rem;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    text-decoration: none;
                    }

                    /* Inner content styles */
                    .addon-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    }

                    .addon-title {
                    font-weight: bold;
                    color: #ffffff;
                    margin-bottom: 5px;
                    }

                    .addon-percentage {
                    font-size: 0.9rem;
                    color: #ffffff;
                    }

                    /* Hover effect */
                    .addon-item:hover, .addon-item.active {
                    background-color: rgba(255, 255, 255, 0.13);
                    border-color: #ffffff;
                    }

                    .account-image{
                    object-fit: cover; 
                    max-width: 17rem !important; 
                    min-height: 20rem !important;
                    max-height: 25rem !important;
                    }

                    .phase-toggle-btn-group {
                        display: inline-flex; /* Centers the group itself as an inline element */
                        justify-content: center; /* Centers buttons within the group */
                        align-items: center;
                        margin-top: 1rem;
                        max-width: 16rem;
                        position: relative;
                    }

                    /* Responsive adjustments for smaller screens */
                    @media (max-width: 576px) {
                        .phase-toggle-btn {
                            padding: 0.4rem 1rem; /* Smaller padding */
                            font-size: 0.9rem; /* Smaller font size */
                        }
                        .phase-toggle-btn-group {
                            max-width: 100%; /* Allow full width on mobile */
                        }
                    }

                    @media (max-width: 768px) {
                        .slider-label {
                            margin-left: -0.5rem;
                        }

                        .currency-btn {
                        margin-left: 1.6rem;
                        margin-top: -1.5rem;
                        margin-bottom: 2rem;
                        }

                        .rc-slider {
                        right: 6rem !important; 
                        width: 25rem !important; 
                        }

                        .row{
                        text-align: center !important;
                    }
                    }
                    `}
                </style>
            </div>
        );
    }
}

export default ConfirmFund;
