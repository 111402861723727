import React from "react";
import Laptop from "../../assets/images/laptop.png";

const EvaluationProcess: React.FC = () => {
  return (
    <div className="container" id="evaluation-process">
      <br />
      <h3 style={{ color: "white" }} className="text-center py-5 fw-bold">
        Our Evaluation Process
      </h3>
      <div className="laptop-bg d-md-flex justify-content-between align-items-center">
        <div>
          <img
            src={Laptop}
            alt="Laptop"
            className="laptop-image" // Add a class for easier targeting
            style={{ maxWidth: "none", width: "40rem" }}
          />
        </div>
        <div>
          <strong><p className="text-center fs-6 fw-bold" style={{ color: "white" }}>
            The key difference between 1-phase and 2-phase fund trading programs lies in the evaluation process.
            <br />
            A 1-phase program gets you funded faster with just one assessment, while a 2-phase program offers a more detailed evaluation across two stages—but here's the kicker:
            <br />
            Phase 2 is cheaper!
          </p></strong>
        </div>
      </div>

      {/* Inline CSS to handle mobile responsiveness */}
      <style>
        {`
          @media (max-width: 768px) {
            .laptop-image {
              max-width: 100% !important; /* Ensure image fits within mobile screens */
            }
          }
        `}
      </style>
    </div>
  );
};

export default EvaluationProcess;
