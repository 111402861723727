import React from "react";
import { toast } from "react-toastify";

export interface AppContextEntity {
  connected: boolean; // test
  setConnected(value: boolean): void; // test

  onConnectClick(): void;
  onDisconnectClick(): void;

  notifyError(msg: string): void;
  notifyInfo(msg: string): void;
}

export const AppContext = React.createContext<AppContextEntity | null>(null);

export type AppProviderProps = {
  // using `interface` is also ok
  // message: string;
  children: any;
};

type AppProviderState = {
  count: number; // test
  connected: boolean; // test
};

export class AppProvider extends React.Component<AppProviderProps, AppProviderState> {
  state: AppProviderState = {
    count: 123, // test
    connected: false // test
  };

  // #region
  // centralServerClient: CentralServerClient;
  // #endregion

  // #region constructor
  // constructor(props: AppProviderProps) {
  //   super(props);

  //   // this.centralServerClient = new CentralServerClient();
  // }
  // #endregion

  // #region React Events
  componentDidMount = async () => {};

  componentWillUnmount = () => {};
  // #endregion

  // #region Handlers
  onConnectClick = async () => {
    await this.checkConnected(true);
  };

  onDisconnectClick = async () => {};
  // #endregion

  // #region System Functions
  // #endregion

  // #region Helper functions
  checkConnected = async (requested: boolean) => {};
  // #endregion

  // #region State Setters
  setConnected = async (value: boolean) => {
    await this.setState(
      (prevState) => {
        return {
          connected: value
        };
      },
      () => {}
    );
  };
  // #endregion

  // #region functions
  notifyError = (msg: string) => {
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark"
    });
  };

  notifyInfo = (msg: string) => {
    toast.info(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark"
    });
  };
  // #endregion

  render() {
    const contextEntity: AppContextEntity = {
      connected: this.state.connected,
      setConnected: this.setConnected,

      onConnectClick: this.onConnectClick,
      onDisconnectClick: this.onDisconnectClick,

      notifyError: this.notifyError,
      notifyInfo: this.notifyInfo
    };

    return <AppContext.Provider value={contextEntity}>{this.props.children}</AppContext.Provider>;
  }
}
