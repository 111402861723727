import React from "react";
import { Layout } from "../layout";

export type AboutUsPageProps = {};

type AboutUsPageState = {};

export class AboutUsPage extends React.Component<AboutUsPageProps, AboutUsPageState> {
  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: AboutUsPageProps, prevState: AboutUsPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <main className="nk-pages">
            <section className="section section-solution bg-black" id="solution">
              <div className="container" style={{
                border: "1px solid #c1a96c",
                borderRadius: "20px",
                padding: "40px 30px"
              }}>
                <div className="section-head text-center wide-auto">
                  <h1 className="title" style={{ color: "#c1a96c" }}>About Us</h1>
                  {/* <p style={{fontSize: "18px"}}>Have questions about getting started, learning the ropes, or earning with us? Find all the answers in our FAQ section.</p> */}

                </div>
                <p style={{ fontSize: "18px" }}>
                  Welcome to BoneX Fund Trading, where innovation meets opportunity. Building on our success as a leading spot and margin exchange, we are now expanding our horizons to fund and support talented traders like you.
                </p>

                <h3>Why BoneX?</h3>
                <p style={{ fontSize: "18px" }}>
                  <ul>
                    <li>
                      <strong>
                        Trailblazing Exchange:
                      </strong>
                      We are the first in the world to offer a real exchange where traders can experience live trading with BoneX.
                    </li>
                    <li>
                      <strong>
                        Pioneering Free Demo Packets:
                      </strong>
                      BoneX is proud to be the first to provide free demo
                      packets, allowing traders to hone their skills
                      without any financial commitment.
                    </li>
                    <li>
                      <strong>
                        Educational Excellence:
                      </strong>
                      We have successfully educated over 1,800 students,
                      equipping them with the knowledge and skills needed
                      to excel in the trading world.
                    </li>
                    <li>
                      <strong>
                        Substantial Payouts:
                      </strong>
                      To date, we have paid out an impressive $2.8

                      million to our funded traders, showcasing our commitment to rewarding success.
                    </li>
                    <li>
                      <strong>
                        Global Reach:
                      </strong>
                      With traders from around the world, BoneX is
                      a truly international platform, open to anyone over the age of 18,
                      except US persons.
                    </li>
                  </ul>
                </p>
                <h3>Our Mission</h3>
                <p style={{ fontSize: "18px" }}>

                  At BoneX Fund Trading, our mission is to empower traders to reach their
                  full potential. We provide the tools, education,
                  and funding necessary to help traders succeed in the dynamic world of cryptocurrencies,
                  forex, indices, commodities, and stocks.
                </p>
                <h3>What We Offer</h3>
                <p style={{ fontSize: "18px" }}>
                  <ul>
                    <li>
                      <strong>
                        Extensive Funding Options:
                      </strong>
                      Traders can access up to $1,000,000 in virtual funds
                      through our rigorous evaluation process, with the
                      potential to manage even larger amounts based on performance.
                    </li>
                    <li>
                      <strong>
                        High Payout Ratios:
                      </strong>
                      Our funded traders can earn up to 95%
                      of their profits, maximizing their returns.
                    </li>
                    <li>
                      <strong>
                        Comprehensive Support:
                      </strong>
                      From educational resources to a dedicated
                      support team, we are here to assist you every
                      step of the way.
                    </li>
                  </ul>
                  Join BoneX Fund Trading and take the first step towards
                  transforming your trading journey. With our state-of-the-art platform,
                  unparalleled support, and a community of like-minded traders, your success is our priority.
                </p>
              </div>
            </section>


          </main>
        </Layout >
      </React.Fragment >
    );
  }
}
