import React, { useEffect, useRef } from "react";

const StarField: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext("2d")!;

    let screen: { w: number; h: number; c: [number, number] };
    let stars: Star[] = [];

    // Adjust speed based on the device
    const isMobile = window.innerWidth <= 768;

    const params = {
      speed: isMobile ? 0.5 : 2, // Slower speed for mobile
      number: 500,
      extinction: 2,
    };

    class Star {
      x: number;
      y: number;
      z: number;

      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.z = Math.random() * canvas.width;
      }

      move() {
        this.z -= params.speed;
        if (this.z <= 0) {
          this.z = canvas.width;
        }
      }

      show() {
        let x, y, rad, opacity;
        x = (this.x - screen.c[0]) * (canvas.width / this.z);
        x = x + screen.c[0];
        y = (this.y - screen.c[1]) * (canvas.width / this.z);
        y = y + screen.c[1];
        rad = canvas.width / this.z;
        opacity = rad > params.extinction ? 1.5 * (2 - rad / params.extinction) : 1;

        const themeColor = `rgba(193,169,111, ${opacity})`;

        ctx.beginPath();
        ctx.fillStyle = themeColor;
        ctx.arc(x, y, rad, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    function setupStars() {
      screen = {
        w: window.innerWidth,
        h: window.innerHeight,
        c: [window.innerWidth * 0.5, window.innerHeight * 0.5],
      };
      canvas.width = screen.w;
      canvas.height = screen.h;

      if (stars.length === 0) {
        for (let i = 0; i < params.number; i++) {
          stars.push(new Star());
        }
      } else {
        stars.forEach((star) => {
          star.x = Math.random() * canvas.width; // Reposition stars based on new dimensions
          star.y = Math.random() * canvas.height;
        });
      }
    }

    function updateStars() {
      ctx.fillStyle = "#000000"; // Ensure the background stays dark
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      stars.forEach((s) => {
        s.show();
        s.move();
      });
      animationRef.current = window.requestAnimationFrame(updateStars);
    }

    setupStars();
    updateStars();

    window.onresize = () => {
      // Adjust the canvas and star positions without clearing all stars
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      screen = { w: canvas.width, h: canvas.height, c: [canvas.width / 2, canvas.height / 2] };
      stars.forEach((star) => {
        star.x = Math.random() * canvas.width;
        star.y = Math.random() * canvas.height;
      });
    };

    return () => {
      if (animationRef.current !== null) {
        window.cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  return (
    <div className="position-relative" style={{ position: "absolute", height: "100%", width: "100%" }}>
      <canvas ref={canvasRef} id="stars" style={{ height: "100%", width: "100%", backgroundColor: "#000" }} />
    </div>
  );
};

export default StarField;
