import React from "react";

const logos = [
  { src: "https://brightfunded.com/assets/svg/bloomberg.svg", alt: "Bloomberg" },
  { src: "https://brightfunded.com/assets/svg/dow-jones.svg", alt: "Dow Jones" },
  { src: "https://brightfunded.com/assets/svg/yahoo-fin.svg", alt: "Yahoo Finance" },
  { src: "https://brightfunded.com/assets/svg/the-wall-street.svg", alt: "The Wall Street Journal" },
  { src: "https://brightfunded.com/assets/svg/benzinga.svg", alt: "Benzinga" },
  { src: "https://brightfunded.com/assets/svg/dig-journal.svg", alt: "Digital Journal" }
];

const LogoCarousel: React.FC = () => {
  return (
    <div className="container">
      <h3 className="text-center py-5 fw-bold" style={{ color: "white" }}>
        We Are Featured In
      </h3>
      <div className="logo-carousel-container">
        {logos.map((logo, index) => (
          <div className="logo-item" key={index}>
            <img src={logo.src} alt={logo.alt} className="logo-image" />
          </div>
        ))}
      </div>
      <br />
    </div>
  );
};

export default LogoCarousel;
