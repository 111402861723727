import React from "react";

// Props & State
export type FooterProps = {};

type FooterState = {};

export class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <React.Fragment>
        <footer className="nk-footer" style={{ backgroundColor: "#000", paddingTop: "5rem" }}>
          <section className="tc-light bg-transparent">
            <div className="container">
              <div className="nk-block block-footer">
                <div className="row">
                  <div className="col-lg-4 col-sm-4 mb-4 mb-sm-0 text-center">
                    <h2>
                      This Is <strong className="beige-text">Your Chance</strong> To <strong className="beige-text">Trade as a PRO!</strong>
                    </h2>
                  </div>

                  {/* <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                    <div className="wgs wgs-menu">
                      <h6 className="wgs-title" style={{ color: "white" }}>
                        Quick Links
                      </h6>
                      <div className="wgs-body">
                        <ul className="wgs-links">
                          <li>
                            <a href="/#faq">Faqs</a>
                          </li>
                          <li>
                            <a href="/sign-in">Login</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                    <div className="wgs wgs-menu">
                      <h6 className="wgs-title" style={{ color: "white" }}>
                        About
                      </h6>
                      <div className="wgs-body">
                        <ul className="wgs-links">
                          <li>
                            <a href="/about-us">About Us</a>
                          </li>
                          {/* <li>
                            <a href="/contacts">Contact Us</a>
                          </li> */}
                          <li>
                            <a href="/#faq">FAQ</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                    <div className="wgs wgs-menu">
                      <h6 className="wgs-title" style={{ color: "white" }}>
                        Legal
                      </h6>
                      <div className="wgs-body">
                        <ul className="wgs-links">
                          <li>
                            <a href="/terms-of-conditions">Terms of Conditions</a>
                          </li>
                          <li>
                            <a href="/privacy-policy">Privacy Policy</a>
                          </li>
                          <li>
                            <a href="/refund-policy">Refund Policy</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                    <div className="wgs wgs-menu">
                      <h6 className="wgs-title" style={{ color: "white" }}>
                        Quick Links
                      </h6>
                      <div className="wgs-body">
                        <ul className="wgs-links">
                          <li>
                            <a href="/#faq">Faqs</a>
                          </li>
                          <li>
                            <a href="/sign-in">Login</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-4 mb-4 mb-lg-0 order-lg-first">
                    <div className="wgs wgs-text">
                      <div className="wgs-body">
                        <a href="./" className="wgs-logo">
                          <img
                            src="/images/logo/bull-logo.png"
                            srcSet="/images/logo/bull-logo.png"
                            alt="logo"
                            style={{
                              maxWidth: "30%"
                            }}
                          />
                        </a>
                        <p>
                          Copyright © {new Date().getFullYear()} BoneX. <br />
                          All rights reserved.{" "}
                        </p>
                        <p className="copyright-text">You trade, we fund</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="mb-0 me-2">Part of</p>
                    <a href="https://bonex.group/">
                      <img
                        src="./assets/images/bonex_group.png"
                        style={{ width: "120px", verticalAlign: "middle" }}
                        alt="Bonex Group"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </footer>
      </React.Fragment >
    );
  }
}
