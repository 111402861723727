import React from "react";

const stats = [
  {
    value: "$74,128",
    description: "Weekly Payouts to Traders"
  },
  {
    value: "24 / 7",
    description: "Dedicated Support Availability"
  },
  {
    value: "$500M+",
    description: "Monthly Trading Volume Processed"
  },
  {
    value: "10,000+",
    description: "Active Global Traders Network"
  }
];

export const StatsSection: React.FC = () => {
  const statBoxStyle: React.CSSProperties = {
    padding: "30px",
    backgroundColor: "black",
    borderRadius: "12px",
    boxShadow: "0 4px 10px #c1a96c",
    height: "10rem",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: "translateY(0)",
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    (e.currentTarget as HTMLElement).style.transform = "translateY(-10px)";
    (e.currentTarget as HTMLElement).style.boxShadow = "0 12px 30px #c1a96c";
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    (e.currentTarget as HTMLElement).style.transform = "translateY(0)";
    (e.currentTarget as HTMLElement).style.boxShadow = "0 6px 20px #c1a96c";
  };

  return (
    <section className="stats-section" style={{ padding: "50px 0", backgroundColor: "black" }}>
      <h3 className="text-center py-5 fw-bold" style={{ color: "white" }}>
        The Industry-Leading Platform
      </h3>
      <div className="container">
        <div className="row text-center">
          {stats.map((stat, index) => (
            <div className="col-lg-3 col-md-6" key={index} style={{ marginBottom: "20px" }}>
              <div
                className="stat-box"
                style={statBoxStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h2 style={{ fontSize: "36px", fontWeight: "bold", color: "#c1a96c", marginBottom: "10px" }}>{stat.value}</h2>
                <p style={{ fontSize: "16px", color: "#fff" }}>{stat.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Inline media query for mobile view */}
      <style>
        {`
          @media (max-width: 768px) {
            .stat-box {
              height: 12rem !important; /* Set height to 12rem for mobile devices */
            }
          }
        `}
      </style>
    </section>
  );
};
