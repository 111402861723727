import React, { Component, ChangeEvent } from "react";
import Image from '../../assets/images/calculate-man.png'
interface State {
  accountSize: number;
  roi: number;
  personalFunds: number;
  profitWithCryptoFundTrader: number;
  profitWithPersonalFunds: number;
}

type AccountSize = 5000 | 10000 | 25000 | 50000 | 100000 | 200000 | 500000 | 1000000;

export class HomeSectionCalculator extends Component<{}, State> {
  accountSizes: AccountSize[] = [5000, 10000, 25000, 50000, 100000, 200000, 500000, 1000000];

  personalFundsMap: { [key in AccountSize]: number } = {
    5000: 50,
    10000: 100,
    25000: 200,
    50000: 400,
    100000: 800,
    200000: 1000,
    500000: 5000,
    1000000: 10000
  };

  state: State = {
    accountSize: 5000,
    roi: 0,
    personalFunds: 50,
    profitWithCryptoFundTrader: 0,
    profitWithPersonalFunds: 0
  };

  handleAccountSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const accountSize = Number(event.target.value) as AccountSize;
    const personalFunds = this.personalFundsMap[accountSize];
    this.setState({ accountSize, personalFunds }, this.calculateProfits);
  };

  handleROIChange = (event: ChangeEvent<HTMLInputElement>) => {
    const roi = Number(event.target.value);
    this.setState({ roi }, this.calculateProfits);
  };

  calculateProfits = () => {
    const { accountSize, roi, personalFunds } = this.state;
    const profitWithCryptoFundTrader = accountSize * roi * 0.01 * 0.9;
    const profitWithPersonalFunds = personalFunds * roi * 0.01;
    this.setState({ profitWithCryptoFundTrader, profitWithPersonalFunds });
  };

  render() {
    const { accountSize, roi, personalFunds, profitWithCryptoFundTrader, profitWithPersonalFunds } = this.state;

    return (
      <React.Fragment>
        <section className="section section-solution bg-black tc-light pt-0 position-relative" id="calculator">
          <img src={Image} className="position-absolute mask" alt="" />
          <div className="">
            <div className="container">
              <div className="section section-head text-center wide-auto-sm">
                <h2 className="title">Calculate your profit with us</h2>
                <p style={{ color: "#c1a96c" }}>Calculate your potential returns if you trade with us compared to using your own capital.</p>
              </div>

              <div className="nk-block block-contact">
                <div className="row gutter-vr-50px justify-content-center">
                  <div className="col-lg-6 col-md-10">
                    <div className="field-item">
                      <label className="field-label">Select Account Size</label>
                      <select className="input-bordered" value={accountSize} onChange={this.handleAccountSizeChange}>
                        {this.accountSizes.map((size) => (
                          <option key={size} value={size}>
                            {size.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="">
                      <label className="field-label">{roi} % ROI</label>
                      <input
                        className="input-bordered"
                        type="range"
                        min="0"
                        max="100"
                        value={roi}
                        onChange={this.handleROIChange}
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      />
                    </div>
                    <div className="field-item">
                      <label className="field-label">Personal Funds</label>
                      <input
                        className="input-bordered"
                        type="text"
                        value={`${personalFunds.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}
                        readOnly={true}
                      />
                    </div>

                    <div className="field-item">
                      <label className="field-label">Profit with Personal Funds</label>
                      <input
                        className="input-bordered"
                        type="text"
                        value={`${profitWithPersonalFunds.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}
                        readOnly={true}
                      />
                    </div>
                    <div className="field-item">
                      <label className="field-label">Profit with Crypto Fund Trader (90% split)</label>
                      <input
                        className="input-bordered"
                        type="text"
                        value={`${profitWithCryptoFundTrader.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <style>
              {`
              @media (max-width: 768px) {
                .mask {
                  max-width: 100% !important; /* Ensure image fits within mobile screens */
                  position: relative !important;
                   content:url("/static/media/hero-man.b3cf65113e56ed7870d8.png")
                }
              }
            `}
            </style>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
