import React from "react";
import { Layout } from "../layout";

export type RefundPolicyPageProps = {};

type RefundPolicyPageState = {};

export class RefundPolicyPage extends React.Component<RefundPolicyPageProps, RefundPolicyPageState> {
  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: RefundPolicyPageProps, prevState: RefundPolicyPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <main className="nk-pages">
            <section className="section section-solution bg-black" id="solution">
              <div className="container" style={{
                border: "1px solid #c1a96c",
                borderRadius: "20px",
                padding: "30px 80px"
              }}>
                <div className="section-head text-center wide-auto">
                  <h1 className="title">Refund Policy</h1>
                  {/* <p style={{fontSize: "18px"}}>Have questions about getting started, learning the ropes, or earning with us? Find all the answers in our FAQ section.</p> */}

                </div>
                <p>
                  Thank you for choosing BoneX Fund Trading. We value your business and strive to provide the best possible service. If you are not entirely satisfied with your purchase, please refer to the following refund policy:<br />
                  &bull; Eligibility for Refund&#8232;Refunds can be requested within fourteen (14) days from the date of purchase. To qualify for a refund, you must not have opened a position or initiated an order on your demo account. Opening a position will result in the automatic forfeiture of your right to withdraw from the contract. This policy ensures that refunds are only applicable before any services are actively utilized or performed.<br />
                  &bull; Processing of Refunds&#8232;Once approved, refunds will be processed through the same payment platform you used for your purchase. Please note that it may take some time for the refunded amount to appear in your account, depending on your payment provider's policies. The amount refunded will correspond to the full cost of the services as outlined in the initial purchase, minus any relevant fees or deductions. If any discounts or promotional offers were applied during the original transaction, the refunded sum will be adjusted to reflect the reduced price.<br />
                  &bull; Refund Request Procedure&#8232;To request a refund, please notify us by sending an email to support@bonex.fund. In the email, please include &quot;Refund&quot; in the subject line and provide all the relevant details that will allow us to process your request smoothly. The relevant details include full name, account number, date of purchase, reason for requesting a refund, etc. Failure to provide complete information may delay the processing of your request.<br />
                  &bull; Refunds During Dispute Resolution&#8232;If there is an ongoing dispute or claim regarding the service provided, refunds may be temporarily withheld until the matter is fully resolved. This ensures that refunds are not issued prematurely during unresolved disputes, maintaining fairness for both parties.<br />
                  &bull; Refund Discretion&#8232;Refunds are issued at the sole discretion of BoneX Fund Trading. Each request will be reviewed carefully, and refunds may be approved or declined based on the specific circumstances. While we strive to be fair and reasonable, we reserve the right to reject refund requests that do not meet our policy's criteria and the applicable law.<br />
                </p>
              </div>
            </section>
          </main>
        </Layout >
      </React.Fragment >
    );
  }
}
