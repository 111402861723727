import React from "react";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import ReCAPTCHA from "react-google-recaptcha";
import { AppContext } from "../../context/AppProvider";
import { parseCookies, destroyCookie } from "nookies";
import { SignUpResponseDto } from "../../lib/drivers/dto/auth/SignUpResponseDto";
import { SignInResponseDto } from "../../lib/drivers/dto/auth/SignInResponseDto";
import { AuthCookieManager } from "../../lib/Utilities/AuthCookieManager";

export type AuthenticateProps = {
    invitationCode: string;
};

enum FormType {
    SIGN_IN = "SIGN_IN",
    SIGN_UP = "SIGN_UP"
};

type AuthenticateState = {
    form: {
        firstName: string;
        lastName: string;
        email: string;
        signUpPassword: string;
        signInPassword: string;
        passwordRepeat: string;
        invitationCode: string;
        isInvitationCodeDisabled: boolean;
        recaptcha: ReCAPTCHA;
        recaptchaValid: boolean;
        type: FormType;
    };
};

export class Authenticate extends React.Component<AuthenticateProps, AuthenticateState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    refCaptcha: React.RefObject<ReCAPTCHA>;

    constructor(props: AuthenticateProps) {
        super(props);
        this.refCaptcha = React.createRef();

        this.state = {
            form: {
                firstName: "",
                lastName: "",
                email: "",
                signUpPassword: "",
                signInPassword: "",
                passwordRepeat: "",
                invitationCode: "",
                isInvitationCodeDisabled: false,
                recaptcha: null,
                recaptchaValid: false,
                type: FormType.SIGN_IN
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // Retrieve the cookie using nookies
        const cookies = parseCookies();
        const cookieInvitationCode = cookies["invitationCode"];
        if (cookieInvitationCode) {
            this.setState({
                form:
                {
                    ...this.state.form,
                    invitationCode: cookieInvitationCode,
                    isInvitationCodeDisabled: true
                }
            });
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
        });
    };

    handleReCaptchaChange = async (token: string) => {
        this.setState({
            form: { ...this.state.form, recaptchaValid: true }
        });
    };

    async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        console.log(2)

        const recaptchaToken = this.refCaptcha.current.getValue();

        if (!recaptchaToken) {
            this.context.notifyError("CAPTCHA not verified");
            return;
        }

        if (this.state.form.type === FormType.SIGN_UP) {
            const { email, firstName, lastName, signUpPassword, passwordRepeat, invitationCode } = this.state.form;

            if (signUpPassword.length < 6) {
                this.context.notifyError("Password should be at least 6 symbols");

                return;
            }

            if (signUpPassword !== passwordRepeat) {
                this.context.notifyError("Passwords not matching.");
                return;
            }

            const res: SignUpResponseDto = await PortCentralServer.Repo.client.authSignUp({
                email: email,
                password: signUpPassword,
                firstName: firstName,
                lastName: lastName,
                recaptchaToken: recaptchaToken,
                invitationCode: invitationCode
            });

            if (res && res.access_token && res.access_token.length > 0) {
                // Remove the cookie using nookies
                destroyCookie(null, "invitationCode", { path: "/" });

                AuthCookieManager.createSessionCookies({ token: res.access_token, refreshToken: null });
                window.location.reload();
            }
        } else if (FormType.SIGN_IN) {
            const { email, signInPassword } = this.state.form;

            const res: SignInResponseDto = await PortCentralServer.Repo.client.authSignIn({
                email: email,
                password: signInPassword,
                recaptchaToken: recaptchaToken
            });

            if (res && res.access_token && res.access_token.length > 0) {
                AuthCookieManager.createSessionCookies({ token: res.access_token, refreshToken: null });
                window.location.reload();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* Form Section */}
                <section className="text-center" style={{ borderRadius: "2rem", backgroundColor: "transparent", position: "relative" }}>
                    <div className="container">
                        <div className="row justify-content-center" style={{ paddingBottom: "4rem" }}>
                            <div className="col-lg-6 col-md-8 col-sm-12">
                                <div className="d-flex justify-content-center">
                                    <div className="phase-toggle-btn-group" style={{ marginTop: "2rem" }}>
                                        <button
                                            className={`phase-toggle-btn ${this.state.form.type === FormType.SIGN_IN ? "active" : ""}`}
                                            onClick={() => this.setState({
                                                form: {
                                                    ...this.state.form,
                                                    type: FormType.SIGN_IN
                                                }
                                            })}
                                        >
                                            Sign In
                                        </button>
                                        <button
                                            className={`phase-toggle-btn ${this.state.form.type === FormType.SIGN_UP ? "active" : ""}`}
                                            onClick={() => this.setState({
                                                form: {
                                                    ...this.state.form,
                                                    type: FormType.SIGN_UP
                                                }
                                            })}
                                        >
                                            Sign Up
                                        </button>
                                    </div>
                                </div>

                                <form onSubmit={this.handleSubmit} className="p-4" style={{ backgroundColor: "#fff", borderRadius: "1rem", position: "relative", textAlign: "left", zIndex: 10 }}>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="email" style={{ color: "#c1a96c" }}>
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Enter your email for contact"
                                            value={this.state.form.email}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>

                                    {this.state.form.type === FormType.SIGN_UP ?

                                        <>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="firstName" style={{ color: "#c1a96c" }}>
                                                    First name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="firstName"
                                                    name="firstName"
                                                    placeholder="Enter your first name"
                                                    value={this.state.form.firstName}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="lastName" style={{ color: "#c1a96c" }}>
                                                    Last name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="lastName"
                                                    name="lastName"
                                                    placeholder="Enter your last name"
                                                    value={this.state.form.lastName}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="signUpPassword" style={{ color: "#c1a96c" }}>
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="signUpPassword"
                                                    name="signUpPassword"
                                                    placeholder="Enter your password"
                                                    value={this.state.form.signUpPassword}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="passwordRepeat" style={{ color: "#c1a96c" }}>
                                                    Confirm your password
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="passwordRepeat"
                                                    name="passwordRepeat"
                                                    placeholder="Repeat your password"
                                                    value={this.state.form.passwordRepeat}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="invitationCode" style={{ color: "#c1a96c" }}>
                                                    Invitation code
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="invitationCode"
                                                    name="invitationCode"
                                                    placeholder="Enter invitation code"
                                                    value={this.state.form.invitationCode}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="mt-3 d-grid">
                                                <ReCAPTCHA ref={this.refCaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.handleReCaptchaChange} />
                                            </div>
                                        </> : <></>
                                    }

                                    {
                                        this.state.form.type === FormType.SIGN_IN ?
                                            <>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="signInPassword" style={{ color: "#c1a96c" }}>
                                                        Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="signInPassword"
                                                        name="signInPassword"
                                                        placeholder="Enter your password"
                                                        value={this.state.form.signInPassword}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>

                                                <div className="mt-3 d-grid">
                                                    <ReCAPTCHA ref={this.refCaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.handleReCaptchaChange} />
                                                </div>

                                            </> : <></>
                                    }

                                    <button type="submit" className="btn btn-beige btn-round">
                                        Authenticate
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
