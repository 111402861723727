import React from "react";
import WalkMan from "../../assets/images/walk-man.png";

const FundingSpecifications: React.FC = () => {
  return (
    <div>
      <div className="container position-relative z-10 mt-5">
        <div className="py-5 text-center text-white">
          <h1 className="fa-3x fw-bold text-white pb-2">Funding specifications</h1>
          <p style={{ color: "#c1a96c" }}>We are the prop firm with the best conditions for the user in the entire market.</p>
        </div>
      </div>
      <div className="container position-relative z-10" style={{ zIndex: "10" }}>
        <div className="d-md-flex justify-content-between gap-3 ">
          <div className="w-100 w-md-50 mt-3 mt-md-0">
            <div className="col-lg-6 col-md-10" >
              <div className="feature feature-s3 feature-center" style={{ width: "30rem", height: "15rem", background: "linear-gradient(to right, #86704b 0%, #bea67d 100%)", border: "1px solid white", borderRadius: "25px" }}>
                <div className="feature-icon feature-icon-lg" >
                  <em className="icon icon-lg ikon ikon-cash" style={{ color: "white", marginTop: "12px" }} />
                </div>
                <div className="feature-text">
                  <h4 className="title title-md tc-primary">Get up to $1.000.000</h4>
                  <p>Our funding program grants you up to $200,000 upon successfully passing our evaluation. We cover the losses.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 w-md-50 mt-3 mt-md-5">
            <div className="col-lg-6 col-md-10" >
              <div className="feature feature-s3 feature-center" style={{ width: "30rem", height: "15rem", background: "linear-gradient(to right, #86704b 0%, #bea67d 100%)", border: "1px solid white", borderRadius: "25px" }}>
                <div className="feature-icon feature-icon-lg" >
                  <em className="icon icon-lg ikon ikon-globe" style={{ color: "white", marginTop: "12px" }} />
                </div>
                <div className="feature-text">
                  <h4 className="title title-md tc-primary">No day limit</h4>
                  <p>Take your time while going through the phases, do not pressure yourself.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="d-md-flex justify-content-between gap-3 ">
          <div className="w-100 w-md-50 mt-3 mt-md-0">
            <div className="col-lg-6 col-md-10" >
              <div className="feature feature-s3 feature-center" style={{ width: "30rem", height: "15rem", background: "linear-gradient(to right, #86704b 0%, #bea67d 100%)", border: "1px solid white", borderRadius: "25px" }}>
                <div className="feature-icon feature-icon-lg" style={{ color: "white", marginTop: "5px" }}>
                  <em className="con icon-lg ikon ikon-paricle" />
                </div>
                <div className="feature-text">
                  <h4 className="title title-md tc-primary">No trading restriction</h4>
                  <p>Traders are welcome to explore different trading styles and strategies including swing trading, intraday trading, scalping, etc.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 w-md-50 mt-3 md-mt-5">
            <div className="col-lg-6 col-md-10" >
              <div className="feature feature-s3 feature-center" style={{ width: "30rem", height: "15rem", background: "linear-gradient(to right, #86704b 0%, #bea67d 100%)", border: "1px solid white", borderRadius: "25px" }}>
                <div className="feature-icon feature-icon-lg" >
                  <em className="icon icon-lg ikon ikon-target" style={{ color: "white", marginTop: "12px" }} />
                </div>
                <div className="feature-text">
                  <h4 className="title title-md tc-primary">High leverage</h4>
                  <p>We provide leverage of 1:100 to all instruments. Traders can use appropriate lot sizes even while using small stop losses.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={WalkMan} className="position-absolute w-25 d-none d-md-block  " style={{ top: "28%", left: "-3%", zIndex: "10" }} />
      {/* <img src={coin1} className="position-absolute  d-none d-md-block  " style={{ top: "52%", right: "1%", width: "200px", zIndex: "9" }} /> */}
      {/* <img src={coin2} className="position-absolute  d-none d-md-block  " style={{ top: "23%", left: "45%", width: "200px", zIndex: "9" }} /> */}

      <style>
        {`
          @media (max-width: 768px) {
            .feature {
              max-width: 100% !important; /* Ensure image fits within mobile screens */
              height: 16rem !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default FundingSpecifications;
