import React from "react";
import { HomeSectionEvalProcessInfo } from "./HomeSectionEvalProcessInfo";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { FundTradingProgramDto } from "../../lib/drivers/dto/FundTradingProgramDto";
import { LoaderComponent } from "../LoaderComponent";
import { HomeSectionProgramInfo } from "./HomeSectionProgramInfo";
import GetFunded from "./GetFunded";

export type HomeSectionProgramProps = {};

type HomeSectionProgramState = {
  isLoading: boolean;
  dataFundTradingProgramListList: FundTradingProgramDto[];
};

export class HomeSectionProgram extends React.Component<HomeSectionProgramProps, HomeSectionProgramState> {
  state: HomeSectionProgramState = {
    isLoading: true,
    dataFundTradingProgramListList: []
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    const dataFundTradingProgramListList = await this.loadFundTradingProgramList();

    await this.setState((prevState) => {
      return {
        isLoading: false,
        dataFundTradingProgramListList: dataFundTradingProgramListList
      };
    });
  };

  componentDidUpdate = async (prevProps: HomeSectionProgramProps, prevState: HomeSectionProgramState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  private async loadFundTradingProgramList(): Promise<FundTradingProgramDto[]> {
    const result = await PortCentralServer.Repo.client.getFundTradingProgramList();

    return result;
  }
  // #endregion

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            {this.state.dataFundTradingProgramListList && (
              <div id="programs">
                <HomeSectionEvalProcessInfo dataFundTradingProgramList={this.state.dataFundTradingProgramListList} />
                <GetFunded dataFundTradingProgramList={this.state.dataFundTradingProgramListList} />

                {/* <HomeSectionProgramInfo dataFundTradingProgramListList={this.state.dataFundTradingProgramListList} /> */}
              </div>
            )}
          </>
        )}
      </React.Fragment>
    );
  }
}
