import React from "react";

type FaqItem = {
  question: string;
  answer: string;
};

export type HomeSectionFaqProps = {};

export type HomeSectionFaqState = {
  openIndexes: Set<number>;
};

export class HomeSectionFaq extends React.Component<HomeSectionFaqProps, HomeSectionFaqState> {
  state: HomeSectionFaqState = {
    openIndexes: new Set<number>()
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomeSectionFaqProps, prevState: HomeSectionFaqState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  toggleItem = (index: number) => {
    this.setState((prevState) => {
      const openIndexes = new Set(prevState.openIndexes);
      if (openIndexes.has(index)) {
        openIndexes.delete(index);
      } else {
        openIndexes.add(index);
      }
      return { openIndexes };
    });
  };
  // #endregion

  render() {
    const items: FaqItem[] = [
      {
        question: "What is the BoneX Fund Trading Account?",
        answer:
          "At BoneX Fund Trading, we fund traders with up to $1,000,000 in virtual funds who successfully pass our 2-phase or 1-phase evaluations. At the final phase, you can get up to 95% of your overall profit."
      },
      {
        question: "How to Start?",
        answer:
          "To start, select the desired test in the program section. Once your purchase is complete, you will receive your credentials within a few minutes. Then you can start trading and pass the evaluation."
      },
      {
        question: "What about the Evaluation?",
        answer:
          "The evaluation tests the trader's ability. Once successfully passed, you will reach the final phase where you will be able to keep a high percentage of your profits."
      },
      // {
      //   question: "What Capital Can I Get as a Funded Trader?",
      //   answer: "We have different plans, depending on the capital you want. The evaluation will have different prices:\n2 Steps Evaluation:\n$5,000 account: $60\n$10,000 account: $115\n$25,000 account: $220\n$50,000 account: $325\n$100,000 account: $560\n$200,000 account: $1,090\nCustom accounts up to $1,000,000 available with adjusted fees.\n1 Step Evaluation:\n$5,000 account: $59\n$10,000 account: $115\n$25,000 account: $249\n$50,000 account: $379\n$100,000 account: $599\n$200,000 account: $1,149\nCustom accounts up to $1,000,000 available with adjusted fees."
      // },
      {
        question: "Can I Make Money with BoneX Fund Trading?",
        answer:
          "Of course! We focus on evaluating the best traders. Any trader who successfully passes our evaluation and complies with the rules can withdraw up to 95% of the profits earned."
      }
      // {
      //   question: "What Payment Methods are Available?",
      //   answer: "We have payment gateways for credit cards and cryptocurrencies, accepting 11 different ones."
      // },
      // {
      //   question: "Which Countries are Allowed to Join BoneX Fund Trading?",
      //   answer: "We accept and allow all traders from all over the world except US persons on both MetaTrader 5 and our proprietary platform, as long as they are over 18 years old."
      // }
    ];

    return (
      <section className="section section-faq bg-black tc-light" id="faq">
        {/* <div className="ui-shape ui-shape-s6"></div> */}
        <div className="container">
          <div className="section-head text-center wide-auto">
            <h2 className="title">Frequently asked questions</h2>
            <p style={{ color: "#c1a96c" }}>Have questions about getting started, learning the ropes, or earning with us? Find all the answers in our FAQ section.</p>
          </div>
          <div className="nk-block block-faq">
            <div className="row">
              <div className="col">
                <div className="tab-content" >
                  <div className="accordion accordion-s2 accordion-faq" id="faq-1">
                    {items.map((item: FaqItem, index: number) => (
                      <div className="accordion-item accordion-item-s2 current tc-dark" key={index} style={{
                        backgroundColor: "black !important"
                      }}>
                        <h5
                          className={`tc-dark accordion-title accordion-title-sm ${this.state.openIndexes.has(index) ? "" : "collapsed"}`}
                          onClick={() => this.toggleItem(index)}
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-24"
                          aria-expanded="false"
                          style={{
                            backgroundColor: 'black',
                          }}
                        >
                          {" "}
                          <span style={{
                            color: "#c1a96c",
                            backgroundColor: "black"
                          }}>{item.question}</span>
                          <span
                            style={{
                              color: "#c1a96c",
                              marginLeft: "10px",
                              position: "absolute",
                              right: "2rem"
                            }}
                          >
                            {this.state.openIndexes.has(index) ? "▼" : "▶"}
                          </span>
                        </h5>
                        <div id="faq-24-4" className={`collapse ${this.state.openIndexes.has(index) ? "show" : ""}`} data-bs-parent="#faq-24" style={{
                          backgroundColor: 'black',
                        }}>
                          <div className="accordion-content">
                            <p style={{
                              color: "#c1a96c",
                            }}>{item.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
