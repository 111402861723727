import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Image1k from "../../assets/images/1k.png";
import Image2k from "../../assets/images/2k.png";
import Image5k from "../../assets/images/5k.png";
import Image10k from "../../assets/images/10k.png";
import Image25k from "../../assets/images/25k.png";
import Image50k from "../../assets/images/50k.png";
import Image100k from "../../assets/images/100k.png";
import Image200k from "../../assets/images/200k.png";
import Image500k from "../../assets/images/500k.png";
import Image1mil from "../../assets/images/1mil.png";
import { FundTradingProgramDto } from "../../lib/drivers/dto/FundTradingProgramDto";
import { ProgramAddonType } from "../../lib/drivers/dto/enums/ProgramAddonType";
import { IProgramAddonItem } from "../../lib/drivers/dto/config/IProgramAddonItem";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";

interface GetFundedProps {
  dataFundTradingProgramList: FundTradingProgramDto[];
  isGetFundedPage?: boolean;
  slider?: number;
  phase?: string;
}

type AddonSelectionItem = {
  addon: IProgramAddonItem;
  selected: boolean;
  title: string;
};

const GetFunded: React.FC<GetFundedProps> = ({ dataFundTradingProgramList, isGetFundedPage, slider, phase }) => {
  const [activePhase, setActivePhase] = useState("phase1");
  const [sliderValue, setSliderValue] = useState<number | number[]>(500000);
  const [changeImage, setChangeImage] = useState<string>(Image200k);
  const [changeImageText, setChangeImageText] = useState<string>("Imperials Shield");
  const [selectedProgram, setSelectedProgram] = useState<FundTradingProgramDto | null>(null);
  const [addons, setAddons] = useState<AddonSelectionItem[] | null>(null);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  // Marks for equal spacing
  const marks = {
    1000: "$1k",
    45000: "$2.5k",
    90000: "$5k",
    140000: "$10k",
    200000: "$25k",
    260000: "$50k",
    320000: "$100k",
    380000: "$200k",
    440000: "$500k",
    500000: "MILION",
  };

  useEffect(() => {
    if (!addons) {
      const addonsRequest = async () => {
        const addonsRequest: IProgramAddonItem[] = await PortCentralServer.Repo.client.getFundTradingProgramAddonList();
        const addonSelectionList: AddonSelectionItem[] = addonsRequest.map((addon) => ({
          addon,
          selected: false,
          title: getAddonTitle(addon.type)
        }));

        setAddons(addonSelectionList);
      }

      addonsRequest();
    }

    if (slider && phase && firstLoad) {
      setActivePhase(phase);
      setSliderValue(slider)
      handleSliderChange(slider);
      setFirstLoad(false);
    }

    // Find the appropriate program based on the slider value and active phase
    const filteredPrograms = dataFundTradingProgramList.filter(program => {
      let accountSize = 0;
      if (sliderValue === 1000) {
        accountSize = 1000;
      } else if (sliderValue === 45000) {
        accountSize = 2500;
      } else if (sliderValue === 90000) {
        accountSize = 5000;
      } else if (sliderValue === 140000) {
        accountSize = 10000;
      } else if (sliderValue === 200000) {
        accountSize = 25000;
      } else if (sliderValue === 260000) {
        accountSize = 50000;
      } else if (sliderValue === 320000) {
        accountSize = 100000;
      } else if (sliderValue === 380000) {
        accountSize = 200000;
      } else if (sliderValue === 440000) {
        accountSize = 500000;
      } else if (sliderValue === 500000) {
        accountSize = 1000000;
      }

      return (
        Number(program.accountSize) === accountSize &&
        ((activePhase === "phase1" && program.numberStages === 1) ||
          (activePhase === "phase2" && program.numberStages === 2)) // Assuming numberStages indicates phases
      );
    });

    // Set the selected program based on filtered results
    setSelectedProgram(filteredPrograms.length > 0 ? filteredPrograms[0] : null);
  }, [sliderValue, activePhase, dataFundTradingProgramList]);

  const handleSliderChange = (value: number | number[]) => {
    setSliderValue(value);
    switch (value) {
      case 1000:
        setChangeImage(Image1k);
        setChangeImageText("Adventurer's Crest");
        break;
      case 45000:
        setChangeImage(Image2k);
        setChangeImageText("Explorer's Circlet");
        break;
      case 90000:
        setChangeImage(Image5k);
        setChangeImageText("Initiate's Helm");
        break;
      case 140000:
        setChangeImage(Image10k);
        setChangeImageText("Champion's Crest");
        break;
      case 200000:
        setChangeImage(Image25k);
        setChangeImageText("Royal Legacy");
        break;
      case 260000:
        setChangeImage(Image50k);
        setChangeImageText("Titans Crown");
        break;
      case 320000:
        setChangeImage(Image100k);
        setChangeImageText("Emperor's Glory");
        break;
      case 380000:
        setChangeImage(Image200k);
        setChangeImageText("Imperials Shield");
        break;
      case 440000:
        setChangeImage(Image500k);
        setChangeImageText("Elysian Crown");
        break;
      case 500000:
        setChangeImage(Image1mil);
        setChangeImageText("Royal Elysian Crown");
        break;
      default:
        setChangeImage(Image1k);
        setChangeImageText("Celestial Diadem");
    }
  };

  const getAddonTitle = (type: ProgramAddonType): string => {
    switch (type) {
      case ProgramAddonType.BI_WEEKLY_PAYOUT:
        return "Bi-Weekly Payout";
      case ProgramAddonType.WEEKLY_PAYOUT:
        return "Weekly Payout";
      case ProgramAddonType.NO_MIN_TRADING_DAYS:
        return "No Minimum Trading Days";
      default:
        return "Unknown Addon";
    }
  }

  const handleAddonClick = async (event: React.MouseEvent<HTMLAnchorElement>, type: ProgramAddonType) => {
    event.preventDefault();

    // reset this.state.userSubscriptionPurchaseId

    const newAddons = addons.map((item) => {
      if (item.addon.type === type) {
        // Toggle the selected state of the clicked addon
        return { ...item, selected: !item.selected };
      }

      // If the current addon is of type BI_WEEKLY_PAYOUT or WEEKLY_PAYOUT, and a different one is selected, deselect it
      if (
        (type === ProgramAddonType.BI_WEEKLY_PAYOUT && item.addon.type === ProgramAddonType.WEEKLY_PAYOUT) ||
        (type === ProgramAddonType.WEEKLY_PAYOUT && item.addon.type === ProgramAddonType.BI_WEEKLY_PAYOUT)
      ) {
        return { ...item, selected: false };
      }

      return item;
    });

    await setAddons(newAddons);
  };

  const getSelectedAddonsQuery = () => {
    const selectedAddons = addons
      .filter(addon => addon.selected)
      .map(addon => `addon=${encodeURIComponent(addon.addon.type)}`)
      .join('&'); // Join with & to create multiple parameters

    return selectedAddons;
  }

  return (
    <div>
      <div className="container">
        <div className="py-5 text-center text-white">
          <h4 className="fa-4x fw-bold text-white pb-3" style={{ lineHeight: "4.5rem", marginTop: "3rem", fontSize: "3rem" }}>Choose Your Capital and Get Funded</h4>
        </div>
      </div>

      <div className="phase-toggle-container">
        <div className="d-flex justify-content-center">
          <div className="phase-toggle-btn-group">
            <button
              className={`phase-toggle-btn ${activePhase === "phase1" ? "active" : ""}`}
              onClick={() => setActivePhase("phase1")}
            >
              1 Phase
            </button>
            <button
              className={`phase-toggle-btn ${activePhase === "phase2" ? "active" : ""}`}
              onClick={() => setActivePhase("phase2")}
            >
              2 Phase
            </button>
          </div>
        </div>

        {activePhase === "phase1" && (
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="flex-column flex-md-row d-flex justify-content-between align-items-center"
                style={{ marginBottom: "5rem" }}>
                <div id="account-sizes">
                  <div className="d-flex gap-2">
                    <button
                      style={{ marginLeft: "0px !important" }}
                      onClick={() => { window.location.replace("https://app.bonex.fund/pricing") }}
                      className="btn btn-large btn-beige btn-size btn-only"
                    >
                      Only ${selectedProgram?.price}
                    </button>
                  </div>
                </div>
                <div className="w-50 me-3">
                  <div className="slider-label" style={{ color: isGetFundedPage ? "white" : "" }}>Balance</div>
                  <Slider
                    min={1000}
                    max={500000}
                    marks={marks}
                    step={null}
                    value={sliderValue}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>
              <div className="mt-5 overflow-auto" style={{ border: "1px solid #747373", borderRadius: "20px" }}>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={1} className="rounded-top"></th>
                      <th>
                        1 Phase
                        <br />
                        The Challenge
                      </th>
                      <th className="rounded-top">
                        Live Phase
                        <br />
                        The Funded Star
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Trading Period</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Min. Trading Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0].minTradingDays} Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1].minTradingDays} Days</td>
                    </tr>
                    <tr>
                      <td>Max. Daily Loss</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0].maxDailyLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1].maxDailyLoss} %</td>
                    </tr>
                    <tr>
                      <td>Max. Trailing Loss</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0].maxTrailingLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1].maxTrailingLoss} %</td>
                    </tr>
                    <tr>
                      <td>Profit Target</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0].profitTarget} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1].profitTarget || "No Target "} %</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {
                isGetFundedPage ? (
                  <div className="row mt-4">
                    {addons && (
                      <div className="col text-center">
                        <div className="phase1-card">
                          <h5 className="font-size-15" style={{ color: "white" }}>Addons :</h5>
                          <div className="addons-wrapper">
                            {addons.map((item: AddonSelectionItem, key: number) => (
                              <a
                                key={key}
                                className={`addon-item me-3 ${item.selected ? "active" : ""}`}
                                onClick={(e) => handleAddonClick(e, item.addon.type)}
                              >
                                <div className="addon-content">
                                  <span className="addon-title">{item.title}</span>
                                  <span className="addon-percentage">+ {item.addon.priceChangePercentage} %</span>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null
              }
            </div>
            <div className="phase1-card col-12 col-md-4">
              <div className="text-center">
                <img src={changeImage} className="account-image" alt={changeImageText} />
              </div>
              <div className="text-center">
                <h1 className="mt-3">{changeImageText}</h1>
                <h1 style={{ fontWeight: "400", color: "white" }}>${Number(selectedProgram?.accountSize).toLocaleString()}</h1>
                {!isGetFundedPage ?
                  <button
                    onClick={() => { window.location.replace(`/get-funded?slider=${sliderValue}&phase=${activePhase}`) }}
                    className="phase-btn active mt-5" >
                    GET FUNDED
                  </button> :
                  <button
                    onClick={() => {
                      const baseUrl = `/get-funded?fundTradingProgramId=${selectedProgram?.fundTradingProgramId}`;
                      const selectedAddons = getSelectedAddonsQuery();
                      const finalUrl = selectedAddons ? `${baseUrl}&${selectedAddons}` : baseUrl;

                      window.location.replace(finalUrl);
                    }}
                    className="phase-btn active mt-5" >
                    GET FUNDED
                  </button>
                }
              </div>
            </div>
          </div>
        )}

        {activePhase === "phase2" && (
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="flex-column flex-md-row d-flex justify-content-between align-items-center"
                style={{ marginBottom: "5rem" }}>
                <div>
                  <div className="d-flex gap-2">
                    <button
                      onClick={() => { window.location.replace("https://app.bonex.fund/pricing") }}
                      className="btn btn-large btn-beige btn-size btn-only"
                    >
                      Only ${selectedProgram?.price}
                    </button>
                  </div>
                </div>
                <div className="w-50 me-3">
                  <div className="slider-label" style={{ color: isGetFundedPage ? "white" : "" }}>Balance</div>
                  <Slider
                    min={1000}
                    max={500000}
                    marks={marks}
                    step={null}
                    value={sliderValue}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>
              <div className="mt-5 overflow-auto" style={{ border: "1px solid #747373", borderRadius: "20px" }}>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={1} className="rounded-top"></th>
                      <th>
                        1 Phase
                        <br />
                        The Challenge
                      </th>
                      <th className="rounded-top">
                        2 Phase
                        <br />
                        The Challenge 2
                      </th>
                      <th className="rounded-top">
                        Live Phase
                        <br />
                        The Funded Star
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Trading Period</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Min. Trading Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0]?.minTradingDays} Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1]?.minTradingDays} Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[2]?.minTradingDays} Days</td>
                    </tr>
                    <tr>
                      <td>Max. Daily Loss</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0]?.maxDailyLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1]?.maxDailyLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[2]?.maxDailyLoss} %</td>
                    </tr>
                    <tr>
                      <td>Max. Total Loss</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0]?.maxTotalLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1]?.maxTotalLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[2]?.maxTotalLoss} %</td>

                    </tr>
                    <tr>
                      <td>Profit Target</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0]?.profitTarget} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1]?.profitTarget} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[2]?.profitTarget || "No Target "} Days</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {
                isGetFundedPage ? (
                  <div className="row">
                    {addons && (
                      <div className="row mt-4">
                        <div className="col">
                          <div className="phase1-card">
                            <h5 className="font-size-15" style={{ color: "white" }}>Addons :</h5>
                            <div className="addons-wrapper">
                              {addons.map((item: AddonSelectionItem, key: number) => (
                                <a
                                  key={key}
                                  className={`addon-item me-3 ${item.selected ? "active" : ""}`}
                                  onClick={(e) => handleAddonClick(e, item.addon.type)}
                                >
                                  <div className="addon-content">
                                    <span className="addon-title">{item.title}</span>
                                    <span className="addon-percentage">+ {item.addon.priceChangePercentage} %</span>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null
              }
            </div>
            <div className="phase1-card col-12 col-md-4">
              <div className="text-center">
                <img src={changeImage} className="account-image" alt={changeImageText} />
              </div>
              <div className="text-center">
                <h1 className="mt-3">{changeImageText}</h1>
                <h1 style={{ fontWeight: "400", color: "white" }}>${Number(selectedProgram?.accountSize).toLocaleString()}</h1>
                {!isGetFundedPage ?
                  <button
                    onClick={() => { window.location.replace(`/get-funded?slider=${sliderValue}&phase=${activePhase}`) }}
                    className="phase-btn active mt-5" >
                    GET FUNDED
                  </button> :
                  <button
                    onClick={() => {
                      const baseUrl = `/get-funded?fundTradingProgramId=${selectedProgram?.fundTradingProgramId}`;
                      const selectedAddons = getSelectedAddonsQuery();
                      const finalUrl = selectedAddons ? `${baseUrl}&${selectedAddons}` : baseUrl;

                      window.location.replace(finalUrl);
                    }}
                    className="phase-btn active mt-5" >
                    GET FUNDED
                  </button>
                }
              </div>
            </div>
          </div>
        )}
      </div>

      <style>
        {`
        .addons-wrapper {
          gap: 10px;
        }

        .addon-item {
          display: inline-block;
          padding: 10px 15px;
          border: 1px solid white;
          border-radius: 1rem;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s ease;
          text-decoration: none;
        }

        /* Inner content styles */
        .addon-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .addon-title {
          color: #ffffff;
          margin-bottom: 5px;
          width: 7rem
        }

        .addon-percentage {
          font-size: 1rem;
          color: #ffffff;
        }

        .rc-slider {
              right: 6rem !important; 
              width: 25rem !important; 
            }
              
        /* Hover effect */
        .addon-item:hover, .addon-item.active {
          background-color: #c1a96c;
          border-color: #ffffff;
        }

        .account-image{
              object-fit: cover; 
              max-width: 17rem !important; 
              min-height: 20rem !important;
              max-height: 25rem !important;
            }

          @media (max-width: 768px) {
            .slider-label {
                margin-left: -0.5rem;
            }

            .currency-btn {
              margin-left: 1.6rem;
              margin-top: -1.5rem;
              margin-bottom: 2rem;
            }

            .rc-slider {
              right: 6rem !important; 
              width: 25rem !important; 
            }

            .row{
              text-align: center !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default GetFunded;
