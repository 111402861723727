import React from "react";
import { Layout } from "../layout";
import StarField from "../components/home/StarField";
import UserCoin from "../assets/images/user-coin.png";
import Handshake from "../assets/images/handshake.png";
import Growth from "../assets/images/growth.png";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";


export type AffiliatesPageProps = {};

type AffiliatesPageState = {
    form: {
        name: string;
        email: string;
        promotionStrategy: string;
        socialMedia: string[];
        expectedReach: string;
        placeOfResidence: string;
        otherSocialMedia: string;
    };
};

export class AffiliatesPage extends React.Component<AffiliatesPageProps, AffiliatesPageState> {
    constructor(props: AffiliatesPageProps) {
        super(props);
        this.state = {
            form: {
                name: "",
                email: "",
                promotionStrategy: "",
                socialMedia: [],
                otherSocialMedia: "",
                expectedReach: "",
                placeOfResidence: ""
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
        });
    };

    handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        const { socialMedia } = this.state.form;
        const updatedSocialMedia = checked
            ? [...socialMedia, value]
            : socialMedia.filter((platform) => platform !== value);

        this.setState({
            form: {
                ...this.state.form,
                socialMedia: updatedSocialMedia,
            },
        });
    };

    handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { name, email, promotionStrategy, expectedReach, placeOfResidence, socialMedia, otherSocialMedia } = this.state.form;

        const response = await PortCentralServer.Repo.client.userAffiliateApply({
            items: [
                { question: "First Name / Last Name", answers: [name] },
                { question: "Contact Email", answers: [email] },
                { question: "How do you plan to promote our services?", answers: [promotionStrategy] },
                { question: "Expected Reach", answers: [expectedReach] },
                { question: "Place of Residence", answers: [placeOfResidence] },
                { question: "Social Media Platforms", answers: [...socialMedia, otherSocialMedia] },
            ]
        });

        if (response) {
            this.setState({
                form: {
                    name: "",
                    email: "",
                    promotionStrategy: "",
                    socialMedia: [],
                    otherSocialMedia: "",
                    expectedReach: "",
                    placeOfResidence: ""
                }
            })
        }
    };

    render() {
        return (
            <React.Fragment>
                <style>
                    {`
                         @media (max-width: 768px) {
                            section {
                                padding: 2rem 1rem;
                            }

                            h5 {
                                font-size: 1.25rem;
                            }

                            h3 {
                                font-size: 1.75rem;
                            }

                            div {
                                max-width: 100% !important;
                            }

                            .form-control {
                                font-size: 1rem;
                                padding: 0.75rem;
                            }

                            .btn {
                                font-size: 1rem;
                                padding: 0.75rem 1.5rem;
                            }

                            .benefit{
                                margin-bottom: 2rem;
                                left: 21%;
                            }

                            .process{
                                margin-bottom: 7rem;
                                left: 2.5rem;
                            }

                            .heading{
                                font-size: 4rem !important;
                            }
                        }
                    `}
                </style>

                <Layout>
                    <div className="position-absolute" style={{ height: "100%", width: "100%" }}>
                        <div className="home-hero"></div>
                        <StarField />
                    </div>

                    {/* Header Section */}
                    <section className="position-relative">
                        <div className="header-banner has-ovm has-mask position-relative">
                            <div style={{ paddingTop: "15rem", textAlign: "center" }}>
                                <span className="heading" style={{ fontSize: "5rem", fontWeight: "500", color: "#fff" }}>Affiliate</span>
                                <span className="heading" style={{ fontSize: "5rem", fontWeight: "500", color: "#c1a96c" }}> Partnership</span>
                                <br />
                                <div style={{ width: "38rem", margin: "0 auto", textAlign: "center" }}>
                                    <span style={{ fontSize: "1rem", fontWeight: "200", color: "rgb(218, 218, 218)" }}>
                                        Welcome to Bonex Funded's groundbreaking Affiliate Partnership Program. This is your opportunity to transform your community, website, blog, or social media channel into a profitable venture, generating
                                        <span style={{ color: "#c1a96c" }}> passive income effortlessly</span>. By referring a new trader, you receive an attractive 15% commission on
                                        <span style={{ color: "#c1a96c" }}> every purchase</span> they make. An industry game-changer.
                                    </span>
                                </div>
                                <br />
                                <a className="btn btn-beige btn-round" href="https://app.bonex.fund/sign-up">
                                    Register now
                                </a>
                            </div>
                        </div>
                    </section>

                    {/* Benefits Section */}
                    <section className="text-center" style={{ borderRadius: "2rem", backgroundColor: "#f5f5f5", position: "relative", marginTop: "8rem" }}>
                        <h5 className="text-uppercase" style={{ color: "#c1a96c", paddingTop: "4rem" }}>Game-Changing Benefits</h5>
                        <h3 className="text-dark mb-4">What Sets Our Affiliate Program Apart?</h3>
                        <div className="container">
                            <div className="row text-center" style={{ paddingBottom: "4rem" }}>
                                {/* Benefit 1 */}
                                <div className="col-md-2 me-3 benefit" style={{ backgroundColor: "#fff", textAlign: "left", borderRadius: "1rem", width: "17rem" }}>
                                    <div className="p-3 d-flex flex-column align-items-center">
                                        <i className="bi bi-currency-dollar" style={{ color: "#c1a96c", fontSize: '4rem' }}></i>
                                        <h5 className="mt-3" style={{ color: "#000", paddingBottom: "2rem", textAlign: "center" }}>15% Commission</h5>
                                        <p style={{ color: "#000", textAlign: "center" }}>
                                            Receive a generous <strong>15% commission on every purchase as an affiliate.</strong> By guiding new traders, you not only empower their trading potential, but also boost your own earnings simultaneously. It's more than a partnership; it's a journey to mutual success.
                                        </p>
                                    </div>
                                </div>

                                {/* Benefit 2 */}
                                <div className="col-md-2 me-3 benefit" style={{ backgroundColor: "#fff", textAlign: "left", borderRadius: "1rem", width: "17rem" }}>
                                    <div className="p-3 d-flex flex-column align-items-center">
                                        <i className="bi bi-piggy-bank" style={{ color: "#c1a96c", fontSize: '4rem' }}></i>
                                        <h5 className="mt-3" style={{ color: "#000", paddingBottom: "2rem", textAlign: "center" }}>Passive Earnings</h5>
                                        <p style={{ color: "#000", textAlign: "center" }}>
                                            Unlike other programs that reward only first-time purchases, we go further. When you refer a new trader, we link them permanently to your Affiliate ID. This means every purchase they make subsequently turns into a <strong>continuous stream of passive income for you.</strong>
                                        </p>
                                    </div>
                                </div>

                                {/* Benefit 3 */}
                                <div className="col-md-2 me-3 benefit" style={{ backgroundColor: "#fff", textAlign: "left", borderRadius: "1rem", width: "17rem" }}>
                                    <div className="p-3 d-flex flex-column align-items-center">
                                        <i className="bi bi-person-circle" style={{ color: "#c1a96c", fontSize: '4rem' }}></i>
                                        <h5 className="mt-3" style={{ color: "#000", paddingBottom: "2rem", textAlign: "center" }}>Personal Account Manager</h5>
                                        <p style={{ color: "#000", textAlign: "center" }}>
                                            Receive individualized support with a dedicated account manager you can reach via <strong>Whatsapp, Telegram or phone.</strong> They are part of our expert team who is committed to guiding you to success every step of the journey.
                                        </p>
                                    </div>
                                </div>

                                {/* Benefit 4 */}
                                <div className="col-md-2 benefit" style={{ backgroundColor: "#fff", textAlign: "left", borderRadius: "1rem", width: "17rem" }}>
                                    <div className="p-3 d-flex flex-column align-items-center">
                                        <i className="bi bi-aspect-ratio" style={{ color: "#c1a96c", fontSize: '4rem' }}></i>
                                        <h5 className="mt-3" style={{ color: "#000", paddingBottom: "2rem", textAlign: "center" }}>Advanced Affiliate Dashboard</h5>
                                        <p style={{ color: "#000", textAlign: "center" }}>
                                            Unleash the power of the advanced Affiliate Dashboard. This is not just a tool, <strong>it's your control center,</strong> paving your path to success. It allows you to monitor your performance, fine-tune your strategy, and see your growth in real-time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Process Section */}
                    <section className="text-center text-white py-5" style={{ backgroundColor: "transparent" }}>
                        <h5 className="mb-4" style={{ color: "#c1a96c", textAlign: "center", position: "relative" }}>Quick and Easy Process</h5>
                        <h3 className="text-uppercase mb-5" style={{ color: "#fff", textAlign: "center", position: "relative" }}>How To Become Our Partner</h3>\
                        <br />
                        <br />
                        <br />
                        <div className="container">
                            <div className="row">
                                {/* Step 1 */}
                                <div className="col-md-3 me-3 process" style={{ backgroundColor: "#293036", borderRadius: "2rem", width: "22rem" }}>
                                    <div className="p-3">
                                        <img src={UserCoin} style={{ position: "absolute", width: "12rem", bottom: "7rem", right: "5rem" }} />
                                        <p style={{ textAlign: "left" }}>01</p>
                                        <br></br>
                                        <h5 className="mt-3">Sign Up</h5>
                                        <p>Sign up in less than 1 minute.</p>
                                    </div>
                                </div>
                                {/* Step 2 */}
                                <div className="col-md-3 me-3 process" style={{ backgroundColor: "#293036", borderRadius: "2rem", width: "22rem" }}>
                                    <div className="p-3">
                                        <img src={Handshake} style={{ position: "absolute", width: "13rem", bottom: "7rem", right: "5rem" }} />
                                        <p style={{ textAlign: "left" }}>02</p>
                                        <br></br>
                                        <h5 className="mt-3">Refer</h5>
                                        <p>Start referring traders right away.</p>
                                    </div>
                                </div>
                                {/* Step 3 */}
                                <div className="col-md-3 me-3 process" style={{ backgroundColor: "#293036", borderRadius: "2rem", width: "22rem" }}>
                                    <div className="p-3">
                                        <img src={Growth} style={{ position: "absolute", width: "13rem", bottom: "7rem", right: "5rem" }} />
                                        <p style={{ textAlign: "left" }}>03</p>
                                        <br></br>
                                        <h5 className="mt-3">Earn Passive</h5>
                                        <p>Earn passive monthly income from referrals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Form Section */}
                    <section className="text-center" style={{ borderRadius: "2rem", backgroundColor: "#f5f5f5", position: "relative", marginTop: "8rem" }}>
                        <h5 className="text-uppercase" style={{ color: "#c1a96c", paddingTop: "4rem" }}>JOIN THE TRADING UNIVERSE</h5>
                        <h3 className="text-dark mb-4">Reach For The Crowns As A BonexFunded Partner</h3>
                        <div className="container">
                            <div className="row justify-content-center" style={{ paddingBottom: "4rem" }}>
                                <div className="col-lg-6 col-md-8 col-sm-12">
                                    <form onSubmit={this.handleSubmit} className="p-4" style={{ backgroundColor: "#fff", borderRadius: "1rem", position: "relative", textAlign: "left", zIndex: 10 }}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="name" style={{ color: "#c1a96c" }}>
                                                Names
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                placeholder="Enter your first and last name"
                                                value={this.state.form.name}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="email" style={{ color: "#c1a96c" }}>
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Enter your email for contact"
                                                value={this.state.form.email}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="promotionStrategy" style={{ color: "#c1a96c" }}>
                                                How do you plan to promote our services?
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="promotionStrategy"
                                                name="promotionStrategy"
                                                rows={3}
                                                placeholder="Describe your strategy"
                                                value={this.state.form.promotionStrategy}
                                                onChange={this.handleChange}
                                                required
                                            ></textarea>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" style={{ color: "#c1a96c" }}>
                                                Social Media Platforms
                                            </label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="telegram"
                                                    value="Telegram"
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="telegram">
                                                    Telegram
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="discord"
                                                    value="Discord"
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="discord">
                                                    Discord
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="facebook"
                                                    value="Facebook"
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="facebook">
                                                    Facebook
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="twitter"
                                                    value="Twitter"
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="twitter">
                                                    Twitter
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="instagram"
                                                    value="Instagram"
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="instagram">
                                                    Instagram
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="other"
                                                    value="Other"
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="other">
                                                    Other (please specify)
                                                </label>
                                            </div>

                                            {this.state.form.socialMedia.includes("Other") && (
                                                <input
                                                    type="text"
                                                    className="form-control mt-2"
                                                    placeholder="Please specify other social media"
                                                    name="otherSocialMedia"
                                                    value={this.state.form.otherSocialMedia}
                                                    onChange={this.handleChange}
                                                />
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="expectedReach" style={{ color: "#c1a96c" }}>
                                                Expected Reach
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="expectedReach"
                                                name="expectedReach"
                                                placeholder="Enter expected number of referrals"
                                                value={this.state.form.expectedReach}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="placeOfResidence" style={{ color: "#c1a96c" }}>
                                                Place of Residence
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="placeOfResidence"
                                                name="placeOfResidence"
                                                placeholder="Enter your city and country"
                                                value={this.state.form.placeOfResidence}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>

                                        <button type="submit" className="btn btn-beige btn-round">
                                            Submit Application
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </React.Fragment>
        );
    }
}
