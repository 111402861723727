import React from "react";
import { FundTradingProgramDto } from "../../lib/drivers/dto/FundTradingProgramDto";
import { StageType } from "../../lib/drivers/dto/enums/StageType";

export type HomeSectionEvalProcessInfoProps = {
  dataFundTradingProgramList: FundTradingProgramDto[];
};

type HomeSectionEvalProcessInfoState = {
  phases: number;
};

export class HomeSectionEvalProcessInfo extends React.Component<HomeSectionEvalProcessInfoProps, HomeSectionEvalProcessInfoState> {
  state: HomeSectionEvalProcessInfoState = {
    phases: 1
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomeSectionEvalProcessInfoProps, prevState: HomeSectionEvalProcessInfoState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleButtonPhaseClick = async (e: React.FormEvent<HTMLButtonElement>, phases: number) => {
    e.preventDefault();

    await this.setState({ phases });
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    const with_1_phase = this.props.dataFundTradingProgramList.find((ele) => ele.numberStages == 1);
    const stages_1_phase = with_1_phase.fundTradingProgramStages.sort((a, b) => a.sequence - b.sequence);

    const with_2_phases = this.props.dataFundTradingProgramList.find((ele) => ele.numberStages == 2);
    const stages_2_phases = with_2_phases.fundTradingProgramStages.sort((a, b) => a.sequence - b.sequence);

    const stageData = this.state.phases == 1 ? stages_1_phase : stages_2_phases;

    return (
      <React.Fragment>

        <div className="section-tokensale ">
          <div className="container">
            <div className="row pdb-l">
              <div className="col text-center">
                <ul className="btn-grp gutter-20px">
                  <li>
                    <button
                      className={`phase-btn ${this.state.phases === 1 ? 'active' : ''}`}
                      onClick={(e) => this.handleButtonPhaseClick(e, 1)}>
                      1 Phase
                    </button>
                  </li>
                  <li>
                    <button
                      className={`phase-btn ${this.state.phases === 2 ? 'active' : ''}`}
                      onClick={(e) => this.handleButtonPhaseClick(e, 2)}>
                      2 Phases
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nk-block nk-block-token mgb-m30">
              <div className="phase-card-group">
                {stageData.map((itemStage, idxStage) => (
                  <div className="phase-card" key={idxStage}>

                    <div className="phase-card-header">
                      {itemStage.type}
                      {itemStage.type == StageType.PHASE ? ` ${idxStage + 1}` : ""}
                    </div>

                    <div className="phase-card-item">
                      <span className="table-head">Max daily loss</span>
                      <span className="table-des">{itemStage.maxDailyLoss} %</span>
                    </div>
                    <div className="phase-card-item">
                      <span className="table-head">Leverage</span>
                      <span className="table-des">ut to 1:100</span>
                    </div>
                    {itemStage.maxTrailingLoss && (
                      <div className="phase-card-item">
                        <span className="table-head">Max trailing loss</span>
                        <span className="table-des">{itemStage.maxTrailingLoss} %</span>
                      </div>
                    )}
                    {itemStage.maxTotalLoss && (
                      <div className="phase-card-item">
                        <span className="table-head">Max total loss</span>
                        <span className="table-des">{itemStage.maxTotalLoss} %</span>
                      </div>
                    )}
                    <div className="phase-card-item">
                      <span className="table-head">Trading period</span>
                      <span className="table-des">Indefinite</span>
                    </div>
                    <div className="phase-card-item">
                      <span className="table-head">Fee</span>
                      <span className="table-des">{itemStage.type == StageType.LIVE ? "FREE" : "One Time"}</span>
                    </div>
                  </div>

                ))}

                {/* {currentPhaseData.map((phase, index) => (
                    <div className="col" key={index}>
                      <div className="token-info bg-theme">
                        <h4 className="title title-md mb-2 text-sm-center">{phase.title}</h4>
                        <table className="table table-s1 table-token">
                          <tbody>
                            {phase.details.map((detail, idx) => (
                              <div key={idx}>
                                <span className="table-head">{detail.label}</span>
                                <span className="table-des">{detail.value}</span>
                              </div>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))} */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
