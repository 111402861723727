import axios, { AxiosRequestConfig } from 'axios';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { CentralServerEndpointKind, CentralServerPathParamKind } from "./CentralServerClientEnums";
import { FundTradingProgramDto } from "./dto/FundTradingProgramDto";
import { UserAffiliateApplyRequestDto } from './dto/UserAffiliateApplyRequestDto';
import { UserAffiliateApplyResponseDto } from './dto/UserAffiliateApplyResponseDto';
import { SignInRequestDto } from './dto/auth/SignInRequestDto';
import { SignInResponseDto } from './dto/auth/SignInResponseDto';
import { SignUpResponseDto } from './dto/auth/SignUpResponseDto';
import { SignUpRequestDto } from './dto/auth/SignUpRequestDto';
import { IProgramAddonItem } from './dto/config/IProgramAddonItem';
import { AuthCookieManager } from '../Utilities/AuthCookieManager';
import { UrlParamPair } from '../core/ServerDriver/UrlParamPair';
import { PurchaseUserSubscriptionRequestDto } from './dto/PurchaseUserSubscriptionRequestDto';
import { UserSubscriptionPurchaseDto } from './dto/UserSubscriptionPurchaseDto';
import { PromoCodeDto } from './dto/PromoCodeDto';

type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Fund Trading Program
    async getFundTradingProgramList(): Promise<FundTradingProgramDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.fund_trading_programs.toString(), null, null);
            const headers = {};

            const { data } = await axios.get(url, headers);
            const result: FundTradingProgramDto[] = data as FundTradingProgramDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Affiliate Program
    async userAffiliateApply(model: UserAffiliateApplyRequestDto): Promise<UserAffiliateApplyResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_apply.toString(), null, null);

            const { data } = await axios.post(url, model, null);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Users
    async authSignIn(model: SignInRequestDto): Promise<SignInResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_in.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authSignUp(model: SignUpRequestDto): Promise<SignUpResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_up.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Promo codes
    async getPromoCode(promoCode: string): Promise<PromoCodeDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.promo_code.toString(), [
                new UrlParamPair(CentralServerPathParamKind.promoCode, promoCode)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: PromoCodeDto = data as PromoCodeDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Programs
    async getFundTradingProgram(fundTradingProgramId: string): Promise<FundTradingProgramDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_programs_id.toString(), [
                new UrlParamPair(CentralServerPathParamKind.fundTradingProgramId, fundTradingProgramId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: FundTradingProgramDto = data as FundTradingProgramDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getFundTradingProgramAddonList(): Promise<IProgramAddonItem[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.fund_trading_programs_addons.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: IProgramAddonItem[] = data as IProgramAddonItem[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // region subscriptions
    async buySubscription(model: PurchaseUserSubscriptionRequestDto): Promise<UserSubscriptionPurchaseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_buy.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: UserSubscriptionPurchaseDto = data as UserSubscriptionPurchaseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_BASE_URL_API);
    }
    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                Authorization: `Bearer ${token}`
            };
        }

        return headers;
    }
    // #endregion
}