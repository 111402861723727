import React from "react";
import { AppContext } from "../context/AppProvider";
import { slide as Menu } from "react-burger-menu"

// Props & State
export interface HeaderProps {
  toggleMenuCallback(): void;
}

type HeaderState = {
  megaMenu: boolean;
  search: boolean;
  selectedLanguage: string;
  isDropdownOpen: boolean;
  isMobile: boolean;// For dropdown visibility
};

// Flags ka object
const flags: Record<string, string> = {
  en: "🇬🇧", // British flag for English
};
// Inline CSS Styles
const styles = {
  button: {
    background: "linear-gradient(to right, #86704b 0%, #bea67d 100%)", // Gradient background
    color: "white",
    border: "1px solid white",
    borderRadius: "10px", // Rounded edges
    padding: "8px 17px", // Padding inside button
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center", // Center content vertically
    justifyContent: "center", // Center content horizontally
    transition: "background-color 0.3s ease" // Smooth hover effect
  },
  arrow: {
    marginLeft: "10px" // Spacing between text and arrow
  },
  button2: {
    background: "#0000", // Gradient background
    color: "white",
    border: "2px solid #C1A96F", // Border around the button
    borderRadius: "10px", // Rounded edges
    padding: "8px 17px", // Padding inside button
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center", // Center content vertically
    justifyContent: "center" // Center content horizontally
  }
};

export class Header extends React.Component<HeaderProps, HeaderState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: HeaderState = {
    megaMenu: false,
    search: false,
    selectedLanguage: "en", // Initial language state
    isDropdownOpen: false, // Initial state for dropdown visibility
    isMobile: false
  };

  async componentDidMount() {
    // Load the script dynamically
    const script = document.createElement("script");
    script.src = "assets/js/gt.js";
    script.async = true;
    script.dataset.gtWidgetId = "43217984";
    document.body.appendChild(script);

    // Add the inline script
    const inlineScript = document.createElement("script");
    inlineScript.type = "text/javascript";
    inlineScript.innerHTML = `
      window.gtranslateSettings = window.gtranslateSettings || {};
      window.gtranslateSettings['43217984'] = {
        "default_language": "en",
        "languages": ["en", "tr", "th", "vi"],
        "wrapper_selector": "#gt-mordadam-43217984",
        "native_language_names": 1,
        "flag_style": "2d",
        "flag_size": 24,
        "horizontal_position": "inline",
        "flags_location": "flags/"
      };
    `;
    document.body.appendChild(inlineScript);

    if (window.innerWidth <= 768) {
      this.setState({ isMobile: true });
    }

    const cookieName = "googtrans";

    // Helper to get a cookie by name
    const getCookie = (name: string): string | undefined => {
      const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
      return match ? decodeURIComponent(match[2]) : undefined;
    };

    // Helper to set a cookie
    const setCookie = (name: string, value: string, days: number) => {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    };

    // Language mapping
    const countryToLanguageMap: Record<string, string> = {
      TR: "tr",
      TH: "th",
      VN: "vi",
    };

    const languageFromCookie = getCookie(cookieName);

    if (!languageFromCookie) {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          const countryCode = data.country_code;

          const language = countryToLanguageMap[countryCode] || 'en'; // Default to English (1)
          setCookie(cookieName, `/en/${language}`, 30);
          window.location.reload();
        });
    }
  }

  // Language change handler
  handleChangeLanguage = (lang: string) => {
    this.setState({ selectedLanguage: lang, isDropdownOpen: false }); // Update selected language & close dropdown
  };

  // Toggle dropdown visibility
  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen // Toggle dropdown state
    }));
  };

  render() {
    const { selectedLanguage, isDropdownOpen } = this.state;

    return (
      <React.Fragment>
        <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark has-fixed" id="header">
          <div className="header-main">
            <div className="header-container container" style={{ maxWidth: "95%" }}>
              <div className="header-wrap">
                <div className="header-logo logo">
                  <a href="./" className="logo-link">
                    <img className="logo-dark" src="/images/bull-logo.png" srcSet="/images/bull-logo.png 2x" alt="logo" style={{
                      height: "50px"
                    }} />
                    {this.state.isMobile ?
                      <img className="logo-light mobile-view" src="/images/favicon.png" srcSet="/images/favicon.png 2x" alt="logo" /> :
                      <img className="logo-light mobile-view" src="/images/bull-logo.png" srcSet="/images/bull-logo.png 2x" alt="logo" style={{ height: "50px" }} />
                    }
                  </a>
                </div>
                <div className="header-nav-toggle">
                  <div className="row">
                    <div className="col-3">
                      {this.state.isMobile && <div id="gt-mordadam-43217984"></div>}
                    </div>
                    <div className="col-8">
                      <button style={styles.button} onClick={() => window.open("https://bonex.fund/get-funded")}>
                        Get Funded <span style={styles.arrow}>→</span>
                      </button>
                    </div>
                    <div className="col-1">
                      {/* <i onClick={() => {
                        this.setState({
                          megaMenu: true
                        })
                      }} className="fa fa-bars" style={{ color: "#ffffff", paddingTop: "0.7rem", fontSize: "23px" }}></i> */}
                      <Menu right width={"200px"}>
                        <a className="menu-link nav-link" href="/#social-proof">
                          Statistics
                        </a>
                        <a className="menu-link nav-link" href="/#programs">
                          Programs
                        </a>
                        <a className="menu-link nav-link" href="/#tutorial">
                          How To
                        </a>
                        <a className="menu-link nav-link" href="/#calculator">
                          Calculator
                        </a>
                        <a className="menu-link nav-link" href="/affiliates">
                          Affiliates
                        </a>
                        <button className="btn btn-beige" style={{ fontSize: "12px" }} onClick={() => window.location.replace("https://bonex.fund/get-funded")}>
                          Get Funded
                        </button>
                        <button className="btn btn-beige" style={{ fontSize: "12px" }} onClick={() => window.open("https://app.bonex.fund")}>
                          Dashboard
                        </button>
                      </Menu>
                    </div>
                  </div>

                </div>
                <div className="header-navbar">
                  <nav className="header-menu" id="header-menu">
                    <ul className="menu">
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="/#social-proof">
                          Statistics
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="/#programs">
                          Programs
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="/#tutorial">
                          How To
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="/#calculator">
                          Calculator
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="/affiliates">
                          Affiliates
                        </a>
                      </li>
                    </ul>
                    <ul className="menu-btns">
                      <li>
                        <div>
                          {/* Language Dropdown */}
                          <div id="gt-mordadam-43217984"></div>
                        </div>
                      </li>
                      <li>
                        <button style={styles.button} onClick={() => window.open("https://bonex.fund/get-funded")}>
                          Get Funded <span style={styles.arrow}>→</span>
                        </button>
                      </li>
                      <li>
                        <button style={styles.button2} onClick={() => window.open("https://app.bonex.fund")}>Dashboard</button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment >
    );
  }
}
