import React from "react";
import { Layout } from "../layout";
import { HomeSectionProgram } from "../components/home/HomeSectionProgram";
import { HomeSectionPayoutSystem } from "../components/home/HomeSectionPayoutSystem";
import { HomeSectionCalculator } from "../components/home/HomeSectionCalculator";
import { HomeSectionFaq } from "../components/home/HomeSectionFaq";
import { Modal, ModalBody } from "reactstrap";
import { SocialProofSection } from "../components/home/SocialProofSection";
import Card from "../components/Card";
import { FaStar } from "react-icons/fa";
import LogoCarousel from "../components/home/LogoCarousel";
import EvaluationProcess from "../components/home/EvaluationProcess";
import StarField from "../components/home/StarField";
import HeroMan from "../assets/images/hero-man.png";
import FundingSpecifications from "../components/home/FundingSpecifications";
import Tutorial from "../components/home/Tutorial";
export type HomePageProps = {};

type HomePageState = {
  showPopup: boolean;
};

export class HomePage extends React.Component<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);
    this.state = {
      showPopup: true // Initially show the popup
    };
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomePageProps, prevState: HomePageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  closePopup = () => {
    this.setState({ showPopup: false });
  };

  navigateToTokenSection = () => {
    this.closePopup();
    document.getElementById("evaluation-process").scrollIntoView({ behavior: "smooth" });
  };
  // #endregion

  render() {
    const { showPopup } = this.state;

    return (
      <React.Fragment>
        <Layout>
          <main className="nk-pages">
            {/* Popup modal */}
            <Modal
              style={{
                backdropFilter: "blur(3px)",
                fontFamily: "Verdana"
              }}
              isOpen={showPopup}
              toggle={this.closePopup}
              centered
            >
              <ModalBody
                style={{
                  backgroundColor: "black",
                  border: "2px solid #c1a96c",
                  textAlign: "center",
                  padding: "30px",
                  position: "relative" // Allow positioning of the close icon
                }}
                className="cool-text"
              >
                <i
                  onClick={this.closePopup}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "15px",
                    cursor: "pointer"
                  }}
                >
                  <p
                    style={{
                      color: "#c1a96c",
                      fontSize: "20px"
                    }}
                  >
                    X
                  </p>
                </i>
                <img src="images/trade.png"></img>
                {/* <p style={{
                  color: '#c1a96c',
                  fontSize: '20px',
                }}>X</p></i>

                <h1 style={{ color: '#c1a96c', fontSize: '35px' }} className="hit-the-floor">
                  Trade with up to <strong style={{ fontSize: '45px', fontWeight: '600' }}>$1,000,000!</strong>
                </h1>
                <p style={{ color: '#c1a96c', fontSize: '18px' }}>
                  The world’s <strong style={{ fontWeight: '600' }}>FIRST million-dollar</strong> funded account.
                </p> */}
                <button
                  className="btn btn-beige"
                  style={{
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onClick={this.navigateToTokenSection} // Navigate and close modal on button click
                >
                  Learn more
                </button>
              </ModalBody>
            </Modal>

            {/* Existing content */}
            <section className="position-relative">
              <div className="header-banner  has-ovm has-mask position-relative">
                <div className="position-absolute" style={{ height: "100%", width: "100%" }}>
                  <div className="home-hero"></div>
                  <StarField />
                </div>
                <div>
                  <div>
                    <div>
                      <div className="banner-wrap" style={{ paddingTop: "0px", paddingBottom: "" }}>
                        <div className="container flex justify-content-between" style={{ marginTop: "60px", maxWidth: "1450px" }}>
                          <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-sm-10 text-center text-lg-start">
                              <div className="banner-caption cpn tc-light">
                                <div className="cpn-head">
                                  <h1 className="title-large" style={{ textTransform: "uppercase", textAlign: "center" }}>
                                    You <strong className="beige-text">trade</strong>, we <strong className="beige-text">fund</strong>
                                  </h1>
                                  <img src={HeroMan} className="hero-man-mobile" />
                                </div>
                                <div className="cpn-text">
                                  <strong><p className="heading-title-text" style={{ color: "white" }}>
                                    We provide unmatched account sizes at a fraction of the cost, allowing you to maximize your profits with minimal investment. BoneX makes it
                                    simple for traders to succeed with the best funding and lowest prices in the industry.
                                  </p></strong>
                                  {/* <p>
                                  To participate in our program, traders are required to successfully complete an examination process. Upon passing, you will be granted access to
                                  up to $200,000 in virtual funds and will be eligible to receive 90% of the profits you earn.
                                </p> */}
                                  <ul className="tick-list" style={{ textAlign: "center" }}>
                                    <a className="btn btn-beige btn-large btn-round" style={{ marginBottom: "1rem" }} href="#programs">
                                      The Best Prices
                                    </a>
                                    <a className="btn btn-beige btn-large btn-round" style={{ marginBottom: "1rem" }} href="https://bonex.net">
                                      The Best Interface
                                    </a>
                                    <a className="btn btn-beige btn-large btn-round" href="#account-sizes">
                                      The Biggest Accounts
                                    </a>
                                  </ul>
                                </div>
                                <div className="cpn-action">
                                  <div className="cpn-btns">
                                    <a className="btn btn-beige btn-large btn-round" href="https://app.bonex.fund/sign-up">
                                      Register now
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 order-lg-last  ">
                              <div className="flex-column d-flex flex-md-row gap-4 justify-content-center align-items-center pe-md-6 ">
                                <Card index={0} />
                                <div className="mt-4 mt-md-6">
                                  <Card index={1} />
                                </div>
                              </div>

                              <div className="flex-column mt-4 d-flex flex-md-row gap-4 justify-content-end align-items-center mb-3">
                                <Card index={2} />
                              </div>

                              <img src={HeroMan} className="hero-man d-none d-md-block" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="scrolling-text-container">
                    <div className="scrolling-text">
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Unlimited Scaling Plan
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Unlimited Days
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Up to 100% Profit Split
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> No Consistency Rules
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Swap Free Available
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Industry's Tightest Spreads
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Unlimited Scaling Plan
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Unlimited Days
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Up to 100% Profit Split
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> No Consistency Rules
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Swap Free Available
                      </span>
                      <span>
                        <FaStar className="star-ico" style={{ marginRight: "8px" }} /> Industry's Tightest Spreads
                      </span>
                    </div>
                  </div>
                </div>

                <style>
                  {`
                  .hero-man-mobile {
                    display: none !important;
                  }
                  @media (max-width: 768px) {
                    .hero-man-mobile {
                      display: block !important;
                      max-width: 100% !important; /* Ensure image fits within mobile screens */
                      position: relative !important;
                    }
                  }
                `}
                </style>
              </div>
            </section>

            <SocialProofSection />
            <div className="bg-black">
              <LogoCarousel />
            </div>
            <div className="bg-black">
              <EvaluationProcess />
            </div>
            <div className="bg-black py-5">
              {/* <PhaseToggle /> */}
              <HomeSectionProgram />
            </div>
            <div className="bg-black py-5">
              <Tutorial />
            </div>
            <div className="bg-black py-5">
              <FundingSpecifications />
            </div>
            {/* <HomeSectionFundingSpecification /> */}

            <HomeSectionPayoutSystem />
            <HomeSectionCalculator />
            <HomeSectionFaq />
          </main>
        </Layout>
      </React.Fragment>
    );
  }
}
