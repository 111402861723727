import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web"; // Import react-spring for animations
import Mike from "../assets/images/mike.png";
import Andy from "../assets/images/andy.png";
import Aarav from "../assets/images/aarav.png";
import k50 from "../assets/images/50k.png";
import k500 from "../assets/images/500k.png";
import million from "../assets/images/1mil.png";

// Define the card data array
const cardData = [
  {
    name: "Mike Dorty",
    location: "Costa Rica",
    account: "Royal Elysian Crown",
    startingBalance: "$1,000,000",
    equityGrowth: "10%",
    payout: 90000, // Change payout to a number for easier animation
    image: Mike,
    accountImage: million
  },
  {
    name: "Andy Donteres",
    location: "Canada",
    account: "Elysian Crown",
    startingBalance: "$500,000",
    equityGrowth: "20%",
    payout: 9000, // Change payout to a number for easier animation
    image: Andy,
    accountImage: k500
  },
  {
    name: "Aarav Sharma",
    location: "India",
    account: "Тitans Crown",
    startingBalance: "$50,000",
    equityGrowth: "45%",
    payout: 20250, // Change payout to a number for easier animation
    image: Aarav,
    accountImage: k50
  },
];

// Define the Card component, which accepts index as a prop
interface CardProps {
  index: number;
}

const Card: React.FC<CardProps> = ({ index }) => {
  // Get the current card data based on the index
  const { name, location, account, startingBalance, equityGrowth, payout, image, accountImage } = cardData[index];

  const [isAnimating, setIsAnimating] = useState(true);

  const animatedPayout = useSpring({
    from: { number: 0 },
    to: { number: payout },
    reset: true, // Reset the animation every time
    config: { duration: 2000 }, // Animation duration for payout
    onRest: () => {
      // Triggered when the animation completes
      setTimeout(() => setIsAnimating(false), 3000); // Wait 3 seconds before restarting
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true); // Restart the animation every 5 seconds
    }, 5000); // 2s for animation + 3s wait

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);


  const floatingAnimation = useSpring({
    loop: true, // Loop the animation infinitely
    to: [{ transform: 'translateY(-20px)' }, { transform: 'translateY(0px)' }],
    from: { transform: 'translateY(0px)' },
    config: { duration: 2000 }, // Duration for one up-and-down cycle
  });

  return (
    <animated.div
      style={{
        ...floatingAnimation, // Floating animation styles
        padding: "10px",
        backgroundColor: "black",
        borderRadius: "12px",
        boxShadow: "0 4px 10px #c1a96c",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
      }}
      className="card"
    >
      <div className="profile">
        <img
          src={image}
          alt="Profile"
          className="profile-image"
        />
        <div className="profile-info">
          <h3 className="name">{name}</h3>
          <p className="location" color="white">{location}</p>
        </div>
      </div>
      <div className="account-details">
        <div className="detail">
          <span className="label" style={{ color: "white" }}>Account</span>
          <strong>
            <span className="value" style={{ color: "#c1a96c" }}>{account}</span>
          </strong>
        </div>
        <div className="detail">
          <span className="label" style={{ color: "white" }}>Starting Balance</span>
          <strong>
            <span className="value" style={{ color: "#c1a96c" }}>{startingBalance}</span>
          </strong>
        </div>
        <div className="detail">
          <span className="label" style={{ color: "white" }}>Equity Growth</span>
          <strong>
            <span className="value" style={{ color: "#c1a96c" }}>{equityGrowth}</span>
          </strong>
        </div>
        <div className="detail">
          <span className="label" style={{ color: "white" }}>Payout</span>
          {/* Use animated span for the payout value */}
          <strong>
            <animated.span className="value" style={{ color: "#c1a96c", fontWeight: "900", fontSize: "1.1rem" }}>
              {animatedPayout.number.to((n) => `$${n.toFixed(0)}`)}
            </animated.span>
          </strong>
        </div>
      </div>
    </animated.div>
  );
};

export default Card;
