import React from "react";
import Image from '../../assets/images/img-4.png'

export type HomeSectionPayoutSystemProps = {};

type HomeSectionPayoutSystemState = {
  isLoading: boolean;
};

export class HomeSectionPayoutSystem extends React.Component<HomeSectionPayoutSystemProps, HomeSectionPayoutSystemState> {
  state: HomeSectionPayoutSystemState = {
    isLoading: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomeSectionPayoutSystemProps, prevState: HomeSectionPayoutSystemState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <section className="section section-features bg-black tc-light">
          <div className="container">
            <div className="nk-block nk-block-lg nk-block-features-s2">
              <div className="row align-items-center flex-row-reverse gutter-vr-30px">
                <div className="col-md-6">
                  <div className="gfx ">
                    <img src={Image} alt="gfx" style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "50rem",
                      maxWidth: "none"
                    }} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="nk-block-text">
                    <h1 className="title" >Tokenization Benefits</h1>
                    <h4 style={{ color: "#c1a96c" }}>BoneX Fund Trader offers a world-leading 90:10 payout—traders take home 90% of the profits!</h4>
                    <br></br>
                    <strong style={{ color: "#c1a96c" }}>
                      With fast, secure crypto withdrawals straight to your wallet, payouts are just a click away. This is the highest payout in the industry, designed for your
                      ultimate success.
                    </strong>
                  </div>
                </div>
              </div>
            </div>

            <style>
              {`
              @media (max-width: 768px) {
                img {
                  max-width: 100% !important; /* Ensure image fits within mobile screens */
                }
              }
            `}
            </style>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
