import React from "react";
import { Layout } from "../layout";
import { HomeSectionProgram } from "../components/home/HomeSectionProgram";
import { HomeSectionFundingSpecification } from "../components/home/HomeSectionFundingSpecification";
import { HomeSectionPayoutSystem } from "../components/home/HomeSectionPayoutSystem";
import { HomeSectionCalculator } from "../components/home/HomeSectionCalculator";
import { HomeSectionFaq } from "../components/home/HomeSectionFaq";

export type PrivacyPolicyPageProps = {};

type PrivacyPolicyPageState = {};

export class PrivacyPolicyPage extends React.Component<PrivacyPolicyPageProps, PrivacyPolicyPageState> {
  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: PrivacyPolicyPageProps, prevState: PrivacyPolicyPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <main className="nk-pages">
            <section className="section section-solution bg-black" id="solution">
              <div className="container" style={{
                border: "1px solid #c1a96c",
                borderRadius: "20px",
                padding: "40px 30px"
              }}>
                <div className="section-head text-center wide-auto">
                  <h1 className="title">Privacy Policy</h1>
                  {/* <p style={{fontSize: "18px"}}>Have questions about getting started, learning the ropes, or earning with us? Find all the answers in our FAQ section.</p> */}

                </div>

                <p> Bonex Fund Trading operated by FALCONE 10X INC (“Company”, “our”, “we” or “us”), offers you, whether on your own or on behalf of any other legal entity (“you” or “User”), access to substantial trading demo accounts (“demo account”) at competitive costs. We are committed to protecting your privacy. This privacy policy (this “Policy”) sets out how we collect and process personal information about you when you visit and/or use our platform (referred to as the “Platform” below) or if you sign up for our newsletter.&#160;&#160; &#160; &#8205;1. Information we collect</p>

                <p>Bonex Fund Trading collects data to enable us to operate the Platform effectively and to provide you with the best experience. You provide some of this data to us directly, such as when registering an account or subscribing to our newsletter. We get some of your data by recording how you interact with the Platform by, for example, using technologies like cookies.&#160; You have choices about the data we collect. When you are asked to provide personal data, you may decline. But if you choose to refrain from providing data necessary to enable us to make the Platform available to you, you may not be able to use it. The data we collect depends on the context of your interactions with the Platform and the choices you make (including your privacy settings). The data we collect includes your first and last name, phone number and email address. We collect data about your device and how you and your device interact with our Platform. For example, this may include your IP address, browser type, operating system, and referring URLs. &#160; &#8205;2. What do we use your information for?</p>

                <p>&#8205;We use the data we collect to operate our business and to make the Platform available to you. This includes using the data to improve our Platform and personalise your experiences. We may also use the data to communicate with you. We may also use the data to manage your email subscriptions, improve the relevance and security of our Platform, respond to User enquiries, and send you periodic marketing communications about our services.</p>

                <p>We use data to provide the Platform to you, improve it, and perform essential business operations. This includes the following:&#160;  &bull; Technical support: We use data to diagnose problems and provide customer care and support services.  &bull; Improving the App: We use data to continually improve the Platform, including system administration, system security, and adding new features or capabilities.  &bull; Business Operations: We use data to develop aggregated analyses and business intelligence that enable us to operate, protect, make informed decisions, and report on the performance of our business.  &bull; Promotions: We may use your data to administer contests, promotions, surveys, or other features.  &bull; Improving Advertising Campaigns: We may use your data to improve our advertising campaigns, primarily to prevent targeting you with advertisements that are not relevant to you.&#160;  &bull; Sending Periodic Emails: We may use your data to send you periodic emails. We may send you occasional marketing emails, which you can unsubscribe from at any time using the link provided in the message.&#160;  &bull; Communications: We use the data we collect to communicate with you and to personalise our communications with you. &#160; &#8205;3. How we protect your information?</p>

                <p>&#8205;We implement a variety of security measures to maintain the safety of your personal information. We use a secure server, Secure Socket Layer (SSL) technology and encrypt the data into our gateway providers&rsquo; database only to be accessible by those authorised with special access rights to such systems and are required to keep the information confidential. We have enforced internal data privacy policies regarding all the personal data you submit to us in order to process it in a compliant and transparent way. &#160; &#8205;4. How do we ensure that our processing systems remain confidential, resilient, and available? &#8205; We implement a variety of measures to ensure that our processing systems remain confidential, resilient and available. Specifically, we have implemented processes to help ensure high availability, business continuity and prompt disaster recovery. We commit to maintaining strong physical and logical access controls and conduct regular penetration testing to identify and address potential vulnerabilities.</p>

                <p>The Platform utilises properly provisioned servers (e.g. multiple load balancers, web servers, replica databases) in case of failure. We take servers out of operation as part of regular maintenance without impacting availability. We keep encrypted backups of data daily. In the case of production data loss (i.e. primary database loss), we will restore organisational data from these backups. Only designated, authorised operations team members have access to configure the infrastructure on an as-needed basis. Specific private keys are required for individual servers, and keys are stored in a secure and encrypted location. &#160; &#8205;5. Do we use cookies?</p>

                <p>&#8205;Yes. Cookies are small files that a site or its service provider transfers to your computer&rsquo;s hard drive through your web browser (if you allow it), which enables the site or service provider&rsquo;s systems to recognise your browser and capture and remember certain information. You can choose to disable cookies, but if you do, your ability to use or access certain parts of the Platform may be affected. &#160; &#8205;6. Links to Other Sites.</p>

                <p>Our Platform may contain links to other sites. You will be directed to that site if you click on a third-party link. Note that we do not operate these external sites. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. &#160; &#8205;7. Your rights as a data subject.</p>

                <p>You have certain guaranteed rights under applicable legislation, and in particular under Regulation EU 2016/679 (General Data Protection Regulation or &lsquo;GDPR&rsquo;). You can learn more about the GDPR and your rights by accessing the European Commission&rsquo;s website.</p>

                <p>Right of information and access</p>

                <p>You have a right to be informed about processing your personal data (and if you did not give it to us, information as to the source), and this Policy intends to provide the information. Of course, if you have any further questions, feel free to contact us.</p>

                <p>Right to rectification You have the right to have any inaccurate personal information about you rectified and to have any incomplete personal information about you completed. You may also request that we restrict the processing of that information. The accuracy of your data is important to us. Contact us if you do not want us to use your personal information in the manner set out in this Policy, need to advise us of any changes to your personal information, or would like more information about how we collect and use your personal information.</p>

                <p>Right to erasure (Right to be &lsquo;forgotten&rsquo;)</p>

                <p>You have the general right to request the erasure of your personal information in the following circumstances:  &bull; the personal data is no longer necessary for the purpose for which it was collected;  &bull; you withdraw your consent to consent-based processing, and no other legal justification for processing applies;  &bull; you object to processing for direct marketing purposes;  &bull; we unlawfully processed your personal information;  &bull; erasure is required to comply with a legal obligation that applies to us.</p>

                <p>We will proceed to comply with an erasure request without delay unless continued retention is necessary for the following:  &bull; exercising the right of freedom of expression and information;  &bull; complying with a legal obligation under EU or other applicable law;  &bull; the performance of a task carried out in the public interest;  &bull; archiving purposes in the public interest, scientific or historical research purposes, or statistical purposes, under certain circumstances;  &bull; the establishment, exercise, or defence of legal claims. &#160; Right to restrict processing and right to object to processing</p>

                <p>You have a right to restrict the processing of your personal information, such as where:  &bull; you contest the accuracy of the personal data;  &bull; where processing is unlawful, you may request, instead of requesting erasure, that we restrict the use of the unlawfully processed personal data;  &bull; we no longer need to process your personal data but need to retain your information for the establishment, exercise, or defence of legal claims.</p>

                <p>You also have the right to object to processing your personal information under certain circumstances, such as where the processing is based on your consent, and you withdraw that consent. This may impact our services, and we will explain this to you if you decide to exercise this right.</p>

                <p>Right to data portability</p>

                <p>Where the legal basis for our processing is your consent or the processing is necessary for the performance of a contract to which you are a party or to take steps at your request prior to entering into a contract, you have a right to receive the personal information you provided to us in a structured, commonly used and machine-readable format, or ask us to send it to another administrator.</p>

                <p>Right to freedom from automated decision-making</p>

                <p>We do not use automated decision-making, but where any automated decision-making takes place, you have the right to express your point of view and to contest the decision, as well as request that decisions based on automated processing concerning you or significantly affecting you and based on your personal data are made by natural persons, not only by computers.</p>

                <p>Right to object to direct marketing (&lsquo;opting out&rsquo;)</p>

                <p>You have a choice about whether or not you wish to receive information from us. We will not contact you for marketing purposes unless you have a business relationship with us or you have freely given your prior consent (such as when you sign-up for our newsletter). On each marketing communication and/or newsletter, we will always provide the option for you to exercise your right to object to processing your personal data for marketing purposes (opting out) by clicking on the &lsquo;unsubscribe&rsquo; button in the emails you receive. You may also opt out at any time by contacting us directly. Please note that any administrative or service-related communications (to offer our services or notify you of an update to this Privacy Policy or applicable terms of business, etc.) will solely be directed at our clients or business partners, and such communications generally do not offer an option to unsubscribe as they are necessary to provide the services requested. Therefore, please be aware that your ability to opt out from receiving marketing and promotional materials does not change our right to contact you regarding your use of our Platform or as part of a contractual relationship we may have with you.</p>

                <p>Right to request access</p>

                <p>You also have a right to access the information we process about you. We will provide you with details of your personal data that we hold or process. To protect your personal data, we follow set storage and disclosure procedures, which means we will require proof of identity from you before disclosing such information. You can exercise this right at any time by contacting us.</p>

                <p>Right to withdraw consent</p>

                <p>Where the legal basis for processing your personal information is your consent, you have the right to withdraw that consent at any time by contacting us.</p>

                <p>Right to object to how we process your personal data</p>

                <p>If you wish to object to how we process your personal data, you can submit a written complaint, and we will investigate the matter. You also have the right to lodge a complaint with the supervisory authority in the country of your habitual residence, place of work, or the place where you allege an infringement of one or more of our rights has taken place if that is based in the EU or EEA.</p>

                <p>Right to file a complaint&#160;</p>

                <p>In case of an alleged violation of the laws applicable to personal data protection, you can file a complaint to the Lead Supervisory Authority for personal data protection for BoneX Fund Trading. &#160; &#8205;8. Data Retention</p>

                <p>&#8205;We may retain your personal data as long as you continue to use the Platform or as long as necessary to fulfil the purposes outlined in this Policy. We may retain personal information for an additional period as is permitted or required under applicable laws, for legal, tax, or regulatory reasons or legitimate and lawful business purposes. &#160; &#8205;9. Changes to our Privacy Policy</p>

                <p>&#8205;We will update and amend this Policy when necessary to reflect changes to our Platform and business model. When we post changes to this statement, we will revise the &quot;Amended&quot; date at the top of the Policy. We encourage you to review this Policy periodically to learn how BoneX Fund Trading protects your information. &#160; 10. How to Contact Us</p>

                <p>If you have a privacy concern, complaint or question, please contact us at: privacy@bonex.net &#8205;We will respond to questions or concerns within 30 days. Unless otherwise stated, BoneX Fund Trading is a data controller for personal data we collect through the Platform subject to this Privacy policy.</p>
              </div>
            </section>


          </main>
        </Layout >
      </React.Fragment >
    );
  }
}
