
export enum CentralServerPathParamKind {
    fundTradingProgramStageId = "{fundTradingProgramStageId}",
    fundTradingProgramId = "{fundTradingProgramId}",
    promoCode = "{promoCode}"
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {
    // fund-trading-program
    fund_trading_programs = "fund-trading/programs",
    fund_trading_programs_addons = "fund-trading/programs/addons",

    //promo-code
    promo_code = "promo-codes/{promoCode}",

    // affiliates
    user_affiliate_apply = "users/affiliates/apply-public",

    // users
    auth_sign_in = "auth/sign-in",
    auth_sign_up = "auth/sign-up",

    // subscriptions
    user_subscriptions_programs_id = "user-subscriptions/programs/{fundTradingProgramId}",
    user_subscriptions_buy = "user-subscriptions/purchases/buy",
}
