import React, { useState } from "react";

const Tutorial: React.FC = () => {
    const [language, setLanguage] = useState<string>("english");

    // YouTube video URLs for different languages
    const videoUrls: { [key: string]: string } = {
        english: "https://youtube.com/embed/PIIaMzQ_isw?feature=share",
        turkish: "https://youtube.com/embed/K0I6sC_MGPU?feature=share",
        vietnamese: "https://youtube.com/embed/ZKIhPZeF0pI?feature=share",
        thai: "https://youtube.com/embed/1HqmQBu7dkU?feature=share",
    };

    const handleLanguageChange = (lang: string) => {
        setLanguage(lang);
    };

    return (
        <div>
            <div className="container position-relative z-10 mt-5">
                <div className="py-5 text-center text-white" id="tutorial">
                    <h1 className="fa-3x fw-bold text-white pb-2">Tutorial</h1>
                    <p style={{ color: "#c1a96c" }}>
                        Welcome to BoneXFunded. Please watch thish quick tutorial to learn how our platform works
                    </p>
                </div>

                {/* Language Switch Buttons */}
                <div className="d-flex flex-wrap justify-content-center mb-4">
                    {["english", "turkish", "vietnamese", "thai"].map((lang) => (
                        <button
                            key={lang}
                            onClick={() => handleLanguageChange(lang)}
                            className={`btn mx-2 mb-2`}
                            style={language === lang ? { backgroundColor: "#c1a96c", color: "white" } : {}}
                        >
                            {lang.charAt(0).toUpperCase() + lang.slice(1)}
                        </button>
                    ))}
                </div>

                {/* YouTube Video */}
                <div className="text-center">
                    <iframe
                        width="100%"
                        height="auto"
                        style={{ width: "28rem", height: "34rem", maxWidth: "1200px", maxHeight: "600px" }}
                        src={videoUrls[language]}
                        title="Tutorial Video"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Tutorial;
